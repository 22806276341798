import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import httpInstances from '../api/axiosInstance';
import { RootState } from '../store';
import { clearStoragesFromTokensAndUserInfo, setTripTokenToStorage } from 'utils/commonFunctions';
export enum UserAccess {
  None = 'None',
  Internal = 'Internal',
  Premium = 'Premium',
  Free = 'Free'
}
type AuthApiState = {
  status: 'idle' | 'loading' | 'failed' | 'fulfilled';
  error: string | null;
  userEmail: string | null;
  tripToken: string | null;
  userAccess: UserAccess | null;
  hsObjectId: string | null;
  metadata: object[] | null;
};

type ErrorResponse = {
  message: string;
};

const initialState: AuthApiState = {
  status: 'idle',
  error: null,
  userEmail: null,
  tripToken: null,
  userAccess: null,
  hsObjectId: null,
  metadata: null
};

export const loginGX = createAsyncThunk('loginGX', async (email: string, { rejectWithValue }) => {
  try {
    await httpInstances.axiosGoInstance.post('/login', `{"email": "${email}"}`);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data);
    }
    throw error;
  }
});

export const loginTrip = createAsyncThunk(
  'loginTrip',

  async (gxToken: string, { rejectWithValue }) => {
    try {
      const { data } = await httpInstances.axiosGoInstance.get('/token', {
        params: { token: gxToken }
      });
      setTripTokenToStorage(data.tripToken);
      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetStatus(state) {
      state.status = 'idle';
    },
    logout(state) {
      state = initialState;
      clearStoragesFromTokensAndUserInfo();
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loginGX.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(loginGX.fulfilled, state => {
        state.status = 'fulfilled';
      })
      .addCase(loginGX.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(loginTrip.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(loginTrip.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.userEmail = action.payload?.userEmail;
        state.tripToken = action.payload?.token;
        state.userAccess = UserAccess[action.payload?.access as UserAccess];
        state.hsObjectId = action.payload?.hsObjectId;
        state.metadata = action.payload?.metadata;
      })
      .addCase(loginTrip.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      });
  }
});

export const { resetStatus, logout } = authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;

export const selectMemoizedAuthState = createSelector(selectAuthState, auth => ({
  status: auth.status,
  userEmail: auth.userEmail
}));

export default authSlice.reducer;
