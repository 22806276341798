import { styled } from '@mui/material/styles';
import { CheckboxProps } from '@mui/material/Checkbox';
import CustomCheckbox from 'components/Checkbox';

const CheckboxIcon = styled('span')(({ theme }) => ({
  borderRadius: 0,
  width: 14,
  height: 14,
  'input:hover ~ &': {
    ...theme.applyStyles('dark', {
      backgroundColor: 'theme.palette.primary.main'
    })
  },
  ...theme.applyStyles('dark', {
    border: `1px solid ${theme.palette.info.dark}`
  })
}));

const IndeterminateIcon = styled(CheckboxIcon)(({ theme }) => ({
  '&::before': {
    display: 'block',
    marginLeft: 1,
    width: 10,
    height: 14,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath" +
      "  d='M23 13H1v-2h22z'" +
      " fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""'
  },
  ...theme.applyStyles('dark', {
    border: `1px solid ${theme.palette.text.primary}`
  })
}));
interface IndeterminateCheckboxProps extends CheckboxProps {
  firstSelected: boolean;
  secondSelected: boolean;
}
const IndeterminateCheckbox = (props: IndeterminateCheckboxProps) => {
  const { firstSelected, secondSelected, ...checkboxProps } = props;
  return (
    <CustomCheckbox
      indeterminateIcon={<IndeterminateIcon />}
      checked={firstSelected && secondSelected}
      indeterminate={firstSelected !== secondSelected}
      {...checkboxProps}
    />
  );
};

export default IndeterminateCheckbox;
