import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import httpInstances from '../api/axiosInstance';
import { RootState } from '../store';
import { SelectedSymbol } from './symbolSelectorSlice';

type CatalogState = {
  status: 'idle' | 'loading' | 'failed' | 'fulfilled';
  error: string | null;
  catalog: CatalogItem[] | null;
};

type ErrorResponse = {
  message: string;
};

export interface CatalogItem {
  Code: string;
  PeriodType: string;
  TimeRef: string;
  IndexModule: string;
  Alias: string;
  Change: string;
  Commodity: string;
  Currency: string;
  DeliveryBasis: string;
  FactsheetVersion: string;
  Frequency: string;
  Group: string;
  HolidayCalendar: string;
  Increment: string;
  Index: string;
  InterAffiliateDataAccepted: string;
  Last: string;
  LastDate: string;
  Period: number;
  PeriodMax: string;
  PeriodMin: string;
  PermissionLatestStatus: string;
  Precision: string;
  PricingBasis: string;
  PriorityToTransactions: string;
  QaSeries: string;
  SoleSourcedDataAccepted: string;
  Source: string;
  StartDate: string;
  EndDate: string;
  Status: string;
  ThirdPartyMappings: string;
  TimeRefDetails: string;
  TimeZone: string;
  Title: string;
  TradingHub: string;
  Units: string;
  Symbol: SelectedSymbol;
  [key: string]: any;
}

const initialState: CatalogState = {
  status: 'idle',
  error: null,
  catalog: null
};

export const getCatalog = createAsyncThunk(
  'getCatalog',
  async (isForwardCurves: boolean, { rejectWithValue }) => {
    try {
      const { data } = await httpInstances.axiosDataInstance.get(
        `/catalogue/${isForwardCurves ? 'curve' : 'spot'}?status=Live,Archive`
      );
      const extendedWithSymbol: CatalogItem[] = data.map((catalogItem: CatalogItem) => ({
        ...catalogItem,
        Symbol: {
          Code: catalogItem.Code,
          Period: String(catalogItem.Period),
          PeriodType: catalogItem.PeriodType,
          TimeRef: catalogItem.TimeRef,
          type: 'Last'
        }
      }));

      return extendedWithSymbol;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

const catalogSlice = createSlice({
  name: 'insights',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCatalog.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getCatalog.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.catalog = action.payload;
      })
      .addCase(getCatalog.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Request failed';
        } else {
          state.error = action.error.message || 'Request failed';
        }
      });
  }
});

export const selectCatalogStatus = (state: RootState) => state.catalog.status;
export default catalogSlice.reducer;
