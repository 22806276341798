import * as React from 'react';
import Button from '@mui/material/Button';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ReactNode } from 'react';
import { Box, SxProps } from '@mui/material';

const CustomPopper = ({
  buttonContent,
  buttonVariant,
  children,
  disabled,
  sx,
  placement = 'auto'
}: {
  buttonContent: ReactNode;
  buttonVariant?: 'text' | 'outlined' | 'contained' | undefined;
  children: ReactNode;
  disabled?: boolean;
  sx?: SxProps;
  placement?: PopperPlacementType;
}) => {
  return (
    <PopupState
      variant='popper'
      popupId='demo-popup-popper'
    >
      {popupState => {
        const handleClose = () => {
          popupState.close();
        };

        return (
          <Box sx={sx}>
            <Button
              disabled={disabled}
              variant={buttonVariant}
              {...bindToggle(popupState)}
            >
              {buttonContent}
            </Button>
            <Popper
              sx={{ zIndex: 10 }}
              {...bindPopper(popupState)}
              transition
              placement={placement}
            >
              {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <Paper sx={{ zIndex: 1 }}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <Box>{children}</Box>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        );
      }}
    </PopupState>
  );
};

export default CustomPopper;
