import {
  Box,
  ButtonBase,
  Drawer,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from '@mui/material';
import { useState } from 'react';
import { FILTER_DRAWER_WIDTH } from '../index';
import Checkbox from 'components/Checkbox';
import { transformCamelCaseToSpaces } from 'utils/commonFunctions';
import { CatalogItem } from 'slices/catalogSlice';
import { getAmountLabel, SpecialFiltersType } from 'pages/Catalog/useCatalog';

const buttonBaseStyle = { color: 'primary.main', px: 0.2, textDecoration: 'underline' };

type SpecialFilterOption = {
  label: string;
  key: keyof SpecialFiltersType;
  visible: boolean;
};

const TableFilters = ({
  filterOpen,
  setFilterOpen,
  columnFiltersValues,
  onFilterChange,
  filteredData,
  specialFilters,
  setSpecialFilters,
  isForwardCurves,
  expandedData
}: {
  filterOpen: boolean;
  isForwardCurves: boolean;
  setFilterOpen: (newFilterOpen: boolean) => void;
  columnFiltersValues: Record<string, { label: string; count: number }[]>;
  onFilterChange: (newFilters: { [key: string]: string[] }) => void;
  filteredData: CatalogItem[];
  specialFilters: SpecialFiltersType;
  setSpecialFilters: (newFilters: SpecialFiltersType) => void;
  expandedData: boolean;
}) => {
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]>>({});

  const handleFilterChange = (column: string, value: string) => {
    setSelectedFilters(prevState => {
      const currentValues = prevState[column] || [];
      const updatedValues = currentValues.includes(value)
        ? currentValues.filter((currentValue: string) => currentValue !== value)
        : [...currentValues, value];

      const newFilters = { ...prevState, [column]: updatedValues };
      onFilterChange(newFilters);
      return newFilters;
    });
  };

  const clearAllFilters = () => {
    setSelectedFilters({});
    setSpecialFilters({
      dataAccessOnly: false,
      oilOnly: false,
      energyTransitionOnly: false
    });
    onFilterChange({});
  };

  const handleSpecialFilterChange = (key: keyof SpecialFiltersType): void => {
    const updatedFilters: SpecialFiltersType = {
      ...specialFilters,
      [key]: !specialFilters[key],
      ...(key === 'oilOnly' ? { energyTransitionOnly: false } : {}),
      ...(key === 'energyTransitionOnly' ? { oilOnly: false } : {})
    };
    setSpecialFilters(updatedFilters);
  };

  const specialFiltersToRender: SpecialFilterOption[] = [
    {
      label: 'Oil Only',
      key: 'oilOnly',
      visible: true
    },
    {
      label: 'Energy Transition Only',
      key: 'energyTransitionOnly',
      visible: true
    }
    // {
    //   label: 'Data I Have Access To',
    //   key: 'dataAccessOnly',
    //   visible: !isForwardCurves
    // }
  ];

  return (
    <Drawer
      anchor='left'
      open={filterOpen}
      onClose={() => setFilterOpen(false)}
      variant='persistent'
      sx={{
        zIndex: 2,
        height: 'calc(100vh - 247px)',
        flexShrink: 0,
        fontSize: 14,
        '& .MuiDrawer-paper': {
          px: 2,
          py: 1,
          borderRight: 'none'
        }
      }}
    >
      <Box
        sx={{
          width: FILTER_DRAWER_WIDTH - 32,
          border: '1px solid',
          borderColor: 'text.primary',
          height: '100%',
          p: 1.5,
          pr: 1,
          backgroundColor: 'secondary.main',
          overflow: 'hidden'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1, mr: 0.5 }}>
          <ButtonBase
            sx={buttonBaseStyle}
            onClick={clearAllFilters}
          >
            Clear all
          </ButtonBase>
        </Box>
        <Box sx={{ height: 'calc(100% - 88px)', overflow: 'auto' }}>
          <List disablePadding>
            {specialFiltersToRender.map(
              ({ label, key, visible }) =>
                !!visible && (
                  <ListItem
                    disablePadding
                    key={key}
                  >
                    <ListItemButton
                      sx={{
                        backgroundColor: 'background.paper',
                        px: 1,
                        mb: 2,
                        '&.Mui-selected': { backgroundColor: 'primary.main' }
                      }}
                      selected={specialFilters[key]}
                      onClick={() => handleSpecialFilterChange(key as keyof SpecialFiltersType)}
                    >
                      <ListItemText
                        primary={label}
                        primaryTypographyProps={{
                          fontSize: '14px',
                          fontWeight: 600
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                )
            )}
          </List>
          <List disablePadding>
            {Object.keys(columnFiltersValues).map(column => (
              <ListItem
                sx={{
                  backgroundColor: 'background.paper',
                  fontSize: '14px',
                  fontWeight: 600,
                  display: 'flex',
                  flexDirection: 'column',
                  p: 0,
                  '&:not(:last-child)': { mb: 2 }
                }}
                key={column}
              >
                <Box
                  sx={{
                    width: '100%',
                    borderBottom: '1px solid',
                    borderBottomColor: 'text.primary',
                    px: 1,
                    pt: 1.5,
                    pb: 0.5
                  }}
                >
                  {transformCamelCaseToSpaces(column)}
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    p: 1,
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column'
                  }}
                >
                  {columnFiltersValues[column].map(({ label, count }) => (
                    <FormControlLabel
                      key={label}
                      sx={{ ml: 0 }}
                      control={
                        <Checkbox
                          checked={selectedFilters[column]?.includes(label) || false}
                          onChange={() => handleFilterChange(column, label)}
                        />
                      }
                      label={`${label} (${count.toLocaleString('en-US')})`}
                    />
                  ))}
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'background.paper',
            p: 1,
            mt: 2,
            mb: 1.5,
            mr: 0.5
          }}
        >
          <Box
            sx={{
              width: 12,
              height: 12,
              backgroundColor: 'primary.main',
              mr: 1
            }}
          />
          {getAmountLabel(filteredData, isForwardCurves, expandedData)}
        </Box>
      </Box>
    </Drawer>
  );
};

export default TableFilters;
