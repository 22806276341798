import { useRef, useState } from 'react';
import { Option } from 'slices/userConfigSlice';

const useSettingsChanger = ({
  options,
  onChange,
  userSetting
}: {
  options: Option[];
  onChange: (userSetting: Option) => void;
  userSetting: Option;
}) => {
  const [open, setOpen] = useState(false);
  const popperAnchorRef = useRef<HTMLDivElement>(null);
  const selectedIndex = options.findIndex(
    option => option.label === userSetting.label && option.value === userSetting.value
  );

  const handleMenuItemClick = (index: number) => {
    onChange(options[index]);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (popperAnchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return {
    popperAnchorRef,
    selectedIndex,
    open,
    handleMenuItemClick,
    handleToggle,
    handleClose
  };
};

export default useSettingsChanger;
