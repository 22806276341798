import { Box, Button, Paper } from '@mui/material';
import { FileDownload, OpenInFull } from '@mui/icons-material';
import LaunchpadPreview from '../Preview';
import { LpData } from '../useLaunchpad';
import LaunchpadGraph from '../Graph';
import LaunchpadStats from '../Stats';
import EmptyLaunchpadScreen from '../EmptyLaunchpadScreen';
import Tabs from 'components/Tabs';
import { SelectedSymbol } from 'slices/symbolSelectorSlice';

const LaunchpadSummary = ({
  chartSeries,
  lpData,
  setLpData,
  setActiveTab,
  isLaunchpadEmpty,
  loading
}: {
  chartSeries:
    | { settings: { type: 'line' }; symbol: { key: SelectedSymbol; group: string } }[]
    | undefined;
  lpData: LpData;
  setLpData: (newLpData: LpData) => void;
  setActiveTab: (index: number) => void;
  isLaunchpadEmpty: boolean;
  loading: boolean;
}) => {
  if (isLaunchpadEmpty) return <EmptyLaunchpadScreen />;

  return (
    <Box sx={{ display: 'flex', mt: 2 }}>
      <Box sx={{ width: 595, mr: 2 }}>
        <LaunchpadStats
          loading={loading}
          lpData={lpData}
          setLpData={setLpData}
        />
        <Paper sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Tabs
            tabs={[{ name: 'Last 4 added' }]}
            activeTab={0}
          />
          <Button
            disabled={loading}
            variant='outlined'
            sx={{ width: 165, mr: 2 }}
            onClick={() => setActiveTab(2)}
          >
            <OpenInFull />
            Full Screen
          </Button>
        </Paper>
        <LaunchpadGraph
          isSummary
          templateChartSeries={chartSeries}
        />
      </Box>
      <Paper sx={{ flexGrow: 1, overflowX: 'auto' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tabs
            tabs={[{ name: 'All Data Hub symbols' }]}
            activeTab={0}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              disabled={loading}
              variant='outlined'
              sx={{ width: 165, mr: 2 }}
              onClick={() => setActiveTab(1)}
            >
              <OpenInFull />
              Full Screen
            </Button>
            <Button
              disabled={loading}
              variant='outlined'
              sx={{ width: 165, mr: 2 }}
              onClick={() => setActiveTab(3)}
            >
              <FileDownload />
              Export data
            </Button>
          </Box>
        </Box>
        <LaunchpadPreview
          isSummary
          lpData={lpData}
          loading={loading}
        />
      </Paper>
    </Box>
  );
};

export default LaunchpadSummary;
