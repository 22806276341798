import useDateText, { DateTextVariants } from './useDateText';

const DateText = ({
  dateString,
  variant = DateTextVariants.FULL
}: {
  dateString: string;
  variant?: DateTextVariants;
}) => {
  const { formatDateString } = useDateText();
  if (!dateString) return null;
  return formatDateString(dateString, variant);
};

export default DateText;
