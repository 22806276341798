import { Box, Button, Paper } from '@mui/material';
import { Add, DeleteOutlined, SelectAll, FileDownload } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import LaunchpadsDropdown from 'pages/Launchpad/ActionBar/LaunchpadsDropdown';
import { useAppDispatch } from 'hooks/redux-hooks';
import { clearLaunchpad } from 'slices/launchpadSlice';
import { setSymbols } from 'slices/symbolSelectorSlice';

const LaunchpadActionBar = ({
  activeTab,
  setActiveTab
}: {
  activeTab: number;
  setActiveTab: (index: number) => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { launchpadSymbols } = useSelector((state: RootState) => state.launchpadSelector);

  return (
    <Paper sx={{ mt: 3, px: 2, py: 1, display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
        {/*<Button*/}
        {/*  disabled*/}
        {/*  variant='outlined'*/}
        {/*  sx={{ mr: 2 }}*/}
        {/*>*/}
        {/*  <BookmarkBorder />*/}
        {/*  Save*/}
        {/*</Button>*/}
        {/*<LaunchpadsDropdown />*/}
        <Button
          disabled={!launchpadSymbols.length}
          variant='outlined'
          // sx={{ ml: 2 }}
          onClick={() => {
            dispatch(clearLaunchpad());
            setActiveTab(0);
          }}
        >
          <DeleteOutlined />
          Clear Data Hub
        </Button>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
        {[0].includes(activeTab) && (
          <Button
            disabled={!launchpadSymbols.length}
            onClick={() => dispatch(setSymbols(launchpadSymbols))}
            variant='outlined'
            sx={{ mr: 2 }}
          >
            <SelectAll />
            Select all data in Data Hub
          </Button>
        )}
        {[1, 2].includes(activeTab) && (
          <Button
            disabled={!launchpadSymbols.length}
            variant='outlined'
            sx={{ width: 165, mr: 2 }}
            onClick={() => setActiveTab(3)}
          >
            <FileDownload />
            Export data
          </Button>
        )}
        <Button
          variant='outlined'
          sx={{ width: 165 }}
          onClick={() => navigate('/catalog')}
        >
          <Add />
          Select more
        </Button>
      </Box>
    </Paper>
  );
};

export default LaunchpadActionBar;
