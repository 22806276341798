import { Box, Button, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DefaultLayout from 'layouts/DefaultLayout';
import { getGxTokenFromStorage } from 'utils/commonFunctions';

const NotFound = () => {
  const navigate = useNavigate();
  const loggedIn = !!getGxTokenFromStorage();

  return (
    <DefaultLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          mt: '8%'
        }}
      >
        <Typography sx={{ fontSize: 180, fontWeight: 900, color: 'primary.main' }}>404</Typography>
        <Typography sx={{ fontSize: 20 }}>This page doesn't exist or was removed.</Typography>
        <Button
          variant='contained'
          sx={{
            my: 4,
            color: 'background.default',
            fontWeight: 'bold',
            fontSize: 18,
            width: 400
          }}
          onClick={() => navigate(loggedIn ? '/markets' : '/login')}
        >
          Return to {loggedIn ? 'Markets' : 'Login'}
        </Button>
        <Typography sx={{ fontSize: 20 }}>
          If you are experiencing any difficulties, please reach out to us at{' '}
          <Link
            href='mailto:support@general-index.com'
            target='_top'
          >
            support@general-index.com
          </Link>
        </Typography>
      </Box>
    </DefaultLayout>
  );
};

export default NotFound;
