import { ReactNode } from 'react';
import { Box, SxProps } from '@mui/material';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
  sx?: SxProps;
  className?: string;
}

const CustomTabPanel = ({ children, value, index, sx, className, ...other }: TabPanelProps) => (
  <div
    role='tabpanel'
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    className={className}
    {...other}
  >
    {value === index && <Box sx={sx}>{children}</Box>}
  </div>
);

export default CustomTabPanel;
