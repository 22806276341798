import { Theme } from '@mui/material';
import { DateTextVariants } from 'components/DateText/useDateText';

export const CHART_GRID_COLOR = '#333';

const baseURL: string = import.meta.env.VITE_REACT_APP_API_ENDPOINT;
export const getBaseImageUrl = () => {
  return `http://go.g-x.${baseURL.includes('dev') ? 'dev' : 'co'}`;
};

const useChartFunctions = () => {
  return {
    createNavigator,
    createPlotOptions,
    createYAxis,
    createXAxis
  };

  function createNavigator(
    theme: Theme,
    formatDateString: (dateString: string, variant: DateTextVariants) => string
  ) {
    return {
      xAxis: {
        gridLineColor: theme.palette.info.dark,
        labels: {
          style: {
            color: theme.palette.text.primary
          },
          formatter: function (): string {
            // @ts-expect-error: highcharts rules.
            const timestamp = this.value;
            return formatDateString(new Date(timestamp).toJSON(), DateTextVariants.MONTH_YEAR);
          }
        }
      },
      enabled: true,
      scrollbar: { enabled: true, barBackgroundColor: theme.palette.info.dark },
      outlineColor: theme.palette.info.dark,
      handles: {
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.info.dark
      }
    };
  }

  function createPlotOptions(theme: Theme, fullScreen: boolean) {
    const plotOptions: Highcharts.PlotOptions = {};
    plotOptions.series = {
      states: {
        inactive: {
          opacity: 1
        },
        hover: {
          enabled: false
        }
      }
    };
    plotOptions.line = {
      states: {
        hover: {
          lineWidth: 3
        }
      }
    };
    if (!fullScreen) {
      plotOptions.series.dataLabels = {
        position: 'right',
        enabled: true,
        useHTML: true,
        formatter: function (): string | undefined {
          const point: any = this;
          const last = point.series.points[point.series.points.length - 1].index;
          if (point.point.index === last) {
            return `<div style="color: ${theme.palette.background.default}; display: inline-flex; padding: 0px 4px 0px 4px; justify-content: center;text-align: center;align-items: center;  width:55px;height:15px; color:  ${theme.palette.background.default};font-size: 10px;font-style: normal;font-weight: 400;line-height: normal;background-color:${point.series.color};">${point.y}</div>`;
          }
        }
      };
    }
    return plotOptions;
  }

  function createYAxis(color: string) {
    return {
      maxPadding: 0.1,
      offset: 35,
      labels: {
        style: {
          color: color,
          fontSize: '10px'
        },
        formatter: function (): string {
          // @ts-expect-error: highcharts rules.
          return this.value.toFixed(2);
        }
      },
      gridLineColor: CHART_GRID_COLOR
    };
  }

  function createXAxis(
    formatDateString: (dateString: string, variant: DateTextVariants) => string,
    color: string
  ) {
    return {
      overscroll: '2px',
      labels: {
        formatter: function (): string {
          // @ts-expect-error: highcharts rules.
          return formatDateString(new Date(this.value).toJSON(), DateTextVariants.MONTH_YEAR) ?? '';
        },
        style: {
          color: color,
          fontSize: '10px'
        }
      },
      gridLineColor: CHART_GRID_COLOR,
      lineColor: CHART_GRID_COLOR,
      tickColor: CHART_GRID_COLOR
    };
  }
};

export default useChartFunctions;
