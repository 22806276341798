import { Box, Button, Link, Typography, Paper } from '@mui/material';
import useJsonCsvPreview from './useJsonCsvPreview';

const JsonPreviewScreen = ({
  exportType,
  from,
  to,
  period,
  periodNumber,
  includeMetadata,
  isCSV,
  exportCurlRequestString
}: {
  exportType: 'all' | 'range' | 'dates';
  from?: string;
  to?: string;
  period?: string;
  periodNumber?: string;
  includeMetadata: boolean;
  isCSV?: boolean;
  exportCurlRequestString: string;
}) => {
  const { copyToClipboard, downloadFile, loading, error, fileContent } = useJsonCsvPreview(
    exportType,
    includeMetadata,
    exportCurlRequestString,
    from,
    to,
    period,
    periodNumber,
    isCSV
  );

  return (
    <Box sx={{ minWidth: 470, maxWidth: 735 }}>
      <Box sx={{ ml: 2, display: 'flex' }}>
        {!isCSV && (
          <Button
            variant='contained'
            onClick={copyToClipboard}
            disabled={isCSV && (!fileContent || loading)}
            sx={{ textTransform: 'uppercase', mr: 2 }}
          >
            Copy To Clipboard
          </Button>
        )}
        <Button
          variant='contained'
          onClick={downloadFile}
          disabled={!fileContent || loading}
          sx={{ textTransform: 'uppercase' }}
        >
          {isCSV ? 'Download' : 'Download Results'}
        </Button>
      </Box>
      <Typography sx={{ p: 2, fontSize: '12px' }}>
        For more information on the GX {isCSV ? 'CSV file format' : 'API'}, please visit our{' '}
        <Link
          href={`https://docs.g-x.co/#${isCSV ? 'index-keys' : 'API'}`}
          target='_blank'
          rel='noopener noreferrer'
          underline='hover'
        >
          documentation site
        </Link>
        .
      </Typography>
      {error && (
        <Typography
          color='error'
          sx={{ p: 2, fontSize: '14px' }}
        >
          An error occurred while fetching data. Please try again.
        </Typography>
      )}
      {loading && isCSV ? (
        <Typography sx={{ p: 2, fontSize: '14px' }}>Fetching data, please wait...</Typography>
      ) : (
        <>
          {isCSV ? (
            <Box
              sx={{
                overflow: 'auto',
                whiteSpace: 'pre',
                p: 2,
                backgroundColor: '#1A224D',
                fontFamily: 'monospace',
                fontSize: '14px',
                height: 541
              }}
            >
              {fileContent}
            </Box>
          ) : (
            <Paper
              sx={{
                m: 2,
                p: 3,
                backgroundColor: '#1A224D',
                fontFamily: 'monospace',
                wordBreak: 'break-all'
              }}
            >
              {exportCurlRequestString}
            </Paper>
          )}
        </>
      )}
    </Box>
  );
};

export default JsonPreviewScreen;
