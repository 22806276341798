import { useTheme } from '@mui/material';
import createRangeSelector from '../../useRangeSelector';
import useChartFunctions, { getBaseImageUrl } from '../../useChartFunctions';
import useTooltip from '../../useTooltip';
import { ChartSeries } from '../../useChartSeries';
import { DEFAULT_CHART_HEIGHT, fullScreenChartPalette } from 'utils/constants';
import useDateText from 'components/DateText/useDateText';
import useUserDetails from 'hooks/useUserAccess';

const CHART_GRID_COLOR = '#333';

const useFullScreenOptions = ({ height }: { height?: number }) => {
  const { checkUserIsFree } = useUserDetails();
  const { formatDateString } = useDateText();
  const theme = useTheme();
  const { createTooltip } = useTooltip();
  const { createNavigator, createPlotOptions, createYAxis, createXAxis } = useChartFunctions();

  const yAxis = createYAxis(theme.palette.background.paper);
  yAxis.maxPadding = 1;
  const createOptions = (chartSeries: ChartSeries[]) => {
    const getWatermarkAttributes = () =>
      checkUserIsFree()
        ? {
            x: window.innerWidth < 1025 ? 'calc(50% - 285px)' : 'calc(50% - 425px)',
            y: window.innerHeight < 750 ? 'calc(50% - 75px)' : 'calc(50% - 125px)',
            width: window.innerWidth < 1025 ? 190 * 3 : '950px',
            height: window.innerHeight < 750 ? 50 * 3 : '250px',
            zIndex: 1
          }
        : {
            x: 'calc(100% - 250px)',
            y: 'calc(100% - 220px)',
            width: '190px',
            height: '50px',
            zIndex: 1
          };
    chartSeries = chartSeries.map(series => {
      series.color =
        series.metadata.combinedIndex > 5
          ? '#A1A6AB'
          : fullScreenChartPalette[series.metadata.combinedIndex % fullScreenChartPalette.length];
      return series;
    });
    return {
      chart: {
        events: {
          load: function () {
            const chart: any = this;
            chart.watermarkImage = chart.renderer
              .image(getBaseImageUrl() + '/watermark-color.svg')
              .attr(getWatermarkAttributes())
              .add();
          },
          redraw: function () {
            const chart: any = this;
            if (chart.watermarkImage) {
              chart.watermarkImage.attr(getWatermarkAttributes());
            }
          }
        },
        type: 'line',
        backgroundColor: theme.palette.text.primary,
        height: height || DEFAULT_CHART_HEIGHT,
        style: {
          fontFamily: 'Cairo, sans-serif'
        },
        zooming: {
          type: 'x'
        }
      },
      lang: {
        contextButtonTitle: 'Export chart'
      },
      exporting: {
        sourceWidth: 1000, // Use the chart's displayed width
        sourceHeight: 420,
        scale: 1,
        enabled: true,
        filename: 'GX-Chart',
        chartOptions: {
          legend: {
            enabled: true,
            itemStyle: {
              fontSize: '0.8em',
              textOverflow: 'ellipsis'
            }
          },
          navigator: {
            enabled: false
          },
          rangeSelector: { enabled: false },
          scrollbar: {
            enabled: false
          }
        },
        buttons: {
          contextButton: {
            symbol:
              "url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.196 19.021 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.021 19.8043 18.55 20 18 20H6ZM12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16Z' fill='%23f8f8f8'/%3E%3C/svg%3E)",
            symbolSize: 20,
            menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
          }
        }
      },
      stockTools: {
        gui: {
          buttons: [
            'indicators',
            'simpleShapes',
            'lines',
            'crookedLines',
            'measure',
            'advanced',
            'toggleAnnotations',
            'verticalLabels',
            'flags',
            'zoomChange',
            'currentPriceIndicator'
          ]
        }
      },
      plotHeight: (height || DEFAULT_CHART_HEIGHT) - 30,
      xAxis: createXAxis(formatDateString, theme.palette.background.paper),
      yAxis: yAxis,
      tooltip: {
        positioner: function (): any {
          return { x: 100, y: 80 };
        },
        formatter: function (): string {
          // @ts-expect-error: highcharts rules.
          const allChartSeries: Highcharts.Series[] = this.series.chart.series;
          // @ts-expect-error: highcharts rules.
          const hoveredPointXValue = this.x;
          return createTooltip(allChartSeries, hoveredPointXValue);
        },
        className: 'tooltip-fullscreen-chart',
        enabled: true,
        split: false,
        followPointer: true,
        backgroundColor: CHART_GRID_COLOR,
        borderRadius: 0,
        useHTML: true,
        shape: 'rect',
        style: {
          zIndex: 1303,
          color: theme.palette.text.primary,
          fontFamily: 'Cairo, sans-serif'
        }
      },
      plotOptions: createPlotOptions(theme, true),
      series: chartSeries,
      legend: { enabled: false },
      navigator: createNavigator(theme, formatDateString),
      rangeSelector: createRangeSelector(theme, chartSeries),
      credits: {
        enabled: false
      }
    };
  };

  return { createOptions };
};

export default useFullScreenOptions;
