import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/redux-hooks';
import { RootState } from 'store';
import { getApiKeys } from 'slices/apiKeyListSlice';

const useTokenGeneration = () => {
  const dispatch = useAppDispatch();
  const apiKeyListState = useSelector((state: RootState) => state.listApiKey);
  const apiKeys = apiKeyListState.apiKeyOutputs ?? [];
  const [error, setError] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getApiKeys()).then(response => {
      if (response.type.includes('rejected')) {
        setError('An error occurred. Please contact the General Index support team.');
      }
    });
  }, []);

  return {
    apiKeys,
    open,
    setOpen,
    error,
    setError
  };
};

export default useTokenGeneration;
