import Stack from '@mui/material/Stack';
import Tile from './Tile';
import { TileType } from './types';

const Tiles = ({ tiles }: { tiles: TileType[] }) => (
  <Stack
    sx={{ width: '100%' }}
    spacing={{ xs: 2, sm: 3 }}
    direction='row'
    useFlexGap
    flexWrap='wrap'
  >
    {!!tiles.length &&
      tiles.map(tile => (
        <Tile
          key={tile?.code}
          tile={tile}
        />
      ))}
  </Stack>
);

export default Tiles;
