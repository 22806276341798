import { Box, TableCell, TableRow, Typography } from '@mui/material';

import CustomCheckbox from 'components/Checkbox';

import { CommoditiesTableRowMetadata } from 'pages/Insights/InsightsComponents/types';

const SymbolSubRow = ({
  symbol,
  metadata,
  periodAbs,
  label,
  checked,
  onChange
}: {
  symbol: any;
  metadata: CommoditiesTableRowMetadata;
  periodAbs: string;
  label: string;
  checked: boolean;
  onChange: () => void;
}) => {
  const { key } = symbol;

  return metadata ? (
    <TableRow key={JSON.stringify(key)}>
      <TableCell
        sx={{ pr: 2, width: '15%' }}
        component='th'
        scope='row'
      >
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
          <CustomCheckbox
            checked={checked}
            onChange={onChange}
          />
          <Typography
            sx={{ pl: 0.5 }}
            variant='caption'
          >
            {' '}
            {label}
          </Typography>
        </Box>
      </TableCell>
      <TableCell sx={{ pr: 2 }}>
        <Typography variant='caption'>{key.Code}</Typography>
      </TableCell>
      <TableCell sx={{ pr: 2 }}>
        <Typography variant='caption'>{metadata.Title}</Typography>
      </TableCell>
      <TableCell sx={{ pr: 2 }}>
        <Typography
          variant='caption'
          sx={{ whiteSpace: 'nowrap' }}
        >
          {periodAbs}
        </Typography>
      </TableCell>
      <TableCell
        sx={{ pr: 2 }}
        align='right'
      >
        <Typography variant='caption'>{metadata.Currency}</Typography>
      </TableCell>
      <TableCell
        sx={{ pr: 2 }}
        align='right'
      >
        <Typography variant='caption'>{metadata.Units}</Typography>
      </TableCell>
    </TableRow>
  ) : (
    <></>
  );
};

export default SymbolSubRow;
