import { ReactElement } from 'react';
import { HubspotTrackingLister } from './hubspotTrackingListener';
import { GoogleTrackingLister } from './googleTrackingListener';
import { PostHogTrackingLister } from './postHogTrackingListener';

export function TrackingLister({ children }: { children: ReactElement }) {
  return (
    <HubspotTrackingLister>
      <GoogleTrackingLister>
        <PostHogTrackingLister>{children}</PostHogTrackingLister>
      </GoogleTrackingLister>
    </HubspotTrackingLister>
  );
}
