import { ReactNode, useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import NavigationMenu from './NavigationMenu';
import SearchAndStreamBar from 'components/SearchAndStreamBar';
import { getInsightsList } from 'slices/insightsListSlice';
import { useAppDispatch } from 'hooks/redux-hooks';
import { getUserConfig } from 'slices/userConfigSlice';
import ResponseMessage from 'components/ResponseMessage';

const MainLayout = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getInsightsList());
    dispatch(getUserConfig());
  }, []);

  return (
    <Container
      sx={theme => ({
        display: 'flex',
        height: '100%',
        width: '100%',
        pr: '0!important',
        pl: '0!important',
        [theme.breakpoints.up('xl')]: {
          borderLeft: '1px solid #121836'
        }
      })}
      maxWidth='xl'
      className='visible-scrollbar'
    >
      <NavigationMenu />
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'calc(100vh + 1px)',

          overflowY: 'auto'
        }}
      >
        <SearchAndStreamBar />
        <SnackbarProvider
          maxSnack={1}
          Components={{
            loading: ResponseMessage,
            error: ResponseMessage,
            success: ResponseMessage,
            info: ResponseMessage
          }}
        >
          {children}
        </SnackbarProvider>
      </Box>
    </Container>
  );
};

export default MainLayout;
