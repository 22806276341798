import { Box, CircularProgress, Typography } from '@mui/material';

const LoadingScreen = () => (
  <Box
    sx={{
      mt: 12,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <CircularProgress sx={{ mr: 2 }} />
    <Typography variant='h4'>Please wait...</Typography>
  </Box>
);

export default LoadingScreen;
