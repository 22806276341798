import { useState } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Collapse, IconButton } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import NavBar from 'components/NavBar';
import GoLogo from 'components/GoLogo';
const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(9)} + 1px)`
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
      }
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
      }
    }
  ]
}));

const NavigationMenu = () => {
  const [open, setOpen] = useState(false);
  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  const handleDrawerOpen = () => {
    if (!open) setOpen(true);
  };

  return (
    <Drawer
      variant='permanent'
      open={open}
      onClick={handleDrawerOpen}
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: 'background.default'
        }
      }}
    >
      <DrawerHeader sx={{ my: 3, px: 2, justifyContent: open ? 'initial' : 'center' }}>
        <GoLogo
          open={open}
          size={40}
          onClick={handleToggleDrawer}
          beta
        />
        <Collapse
          orientation='horizontal'
          timeout={500}
          in={open}
          unmountOnExit
        >
          <IconButton onClick={handleToggleDrawer}>
            <ChevronLeft />
          </IconButton>
        </Collapse>
      </DrawerHeader>
      <NavBar open={open} />
    </Drawer>
  );
};

export default NavigationMenu;
