import { styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

const CheckboxIcon = styled('span')(({ theme }) => ({
  borderRadius: 0,
  width: 14,
  height: 14,
  'input:hover ~ &': {
    ...theme.applyStyles('dark', {
      backgroundColor: 'theme.palette.primary.main'
    })
  },
  ...theme.applyStyles('dark', {
    border: `1px solid ${theme.palette.info.dark}`
  })
}));

const CheckedIcon = styled(CheckboxIcon)(({ theme }) => ({
  '&::before': {
    display: 'block',
    width: 14,
    height: 14,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-1 -1 14 14'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M8.54167 3.0875L3.54167 8.0875L1.25 5.79583L1.8375 5.20833L3.54167 6.90833L7.95417 2.5L8.54167 3.0875Z " +
      "' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""'
  },
  ...theme.applyStyles('dark', {
    border: `1px solid ${theme.palette.text.primary}`
  })
}));

const CustomCheckbox = (props: CheckboxProps) => (
  <Checkbox
    size='small'
    sx={{ '&:hover': { bgcolor: 'transparent' } }}
    checkedIcon={<CheckedIcon />}
    icon={<CheckboxIcon />}
    {...props}
  />
);

export default CustomCheckbox;
