import { useMemo, useState } from 'react';
import { CommoditiesTableTabType } from 'pages/Insights/InsightsComponents/types';
import { debounce } from 'utils/commonFunctions';

const SEARCH_DEBOUNCE_DELAY = 500;

const useCommoditiesTable = (tabs: CommoditiesTableTabType[]) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [highlightedRowId, setHighlightedRowId] = useState<string>('');

  const handleDebouncedSearch = useMemo(
    () =>
      debounce(value => {
        if (value === '') {
          setHighlightedRowId('');
          return;
        }
        findSearchValueInTabs(value as string);
      }, SEARCH_DEBOUNCE_DELAY),
    [tabs]
  );

  const findSearchValueInTabs = (value: string) => {
    tabs.some(({ sections }, tabIndex) => {
      return sections.some(({ rows }) => {
        return rows.some(({ shortTitle, symbol: { key }, diffSymbol: { key: diffKey } }) => {
          const uniqueRowId = JSON.stringify({ shortTitle, key, diffKey });
          if (shortTitle.toLowerCase().includes(value.toLowerCase())) {
            setActiveTab(tabIndex);
            setHighlightedRowId(uniqueRowId);
            return true;
          }
          return false;
        });
      });
    });
  };

  const handleChangeSearch = (value: string) => {
    setSearchValue(value);
    handleDebouncedSearch(value);
  };

  return {
    activeTab,
    setActiveTab,
    searchValue,
    handleChangeSearch,
    highlightedRowId
  };
};

export default useCommoditiesTable;
