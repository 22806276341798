import { LpData } from '../useLaunchpad';

const useLaunchpadStats = (lpData: LpData, setLpData: (newLpData: LpData) => void) => {
  const uniqueColumns = new Set(
    lpData.map(
      ({ symbol }) => `${symbol.TimeRef}-${symbol.Period}-${symbol.PeriodType}-${symbol.Code}`
    )
  );

  const uniqueCommodities = new Map<string, Set<string>>();
  const uniqueTradingHubs = new Map<string, Set<string>>();
  const uniquePricingBasis = new Map<string, Set<string>>();

  lpData.forEach(({ symbol, metadata }) => {
    const columnKey = `${symbol.TimeRef}-${symbol.Period}-${symbol.PeriodType}-${symbol.Code}`;

    if (uniqueColumns.has(columnKey)) {
      if (!uniqueCommodities.has(metadata.Commodity)) {
        uniqueCommodities.set(metadata.Commodity, new Set());
      }
      uniqueCommodities.get(metadata.Commodity)!.add(columnKey);

      if (!uniqueTradingHubs.has(metadata.TradingHub)) {
        uniqueTradingHubs.set(metadata.TradingHub, new Set());
      }
      uniqueTradingHubs.get(metadata.TradingHub)!.add(columnKey);

      if (!uniquePricingBasis.has(metadata.PricingBasis)) {
        uniquePricingBasis.set(metadata.PricingBasis, new Set());
      }
      uniquePricingBasis.get(metadata.PricingBasis)!.add(columnKey);
    }
  });

  const now = new Date();
  const updatedLast24Hours = Array.from(uniqueColumns).filter(columnKey => {
    const transaction = lpData.find(
      ({ symbol }) =>
        `${symbol.TimeRef}-${symbol.Period}-${symbol.PeriodType}-${symbol.Code}` === columnKey
    );
    if (!transaction) return false;
    const transactionDate = new Date(transaction.transactionTime);
    return now.getTime() - transactionDate.getTime() <= 24 * 60 * 60 * 1000;
  }).length;

  const formatStatItems = (statItem: Map<string, Set<string>>) =>
    Array.from(statItem.entries())
      .sort((a, b) => b[1].size - a[1].size)
      .map(([key, value]) => ({ name: key, count: value.size }));

  const deleteFromLpData = (key: string, type: 'Commodity' | 'TradingHub' | 'PricingBasis') => {
    const newLpData = lpData.filter(({ metadata }) => {
      console.log('key', key);
      console.log('type', type);
      if (type === 'Commodity' && metadata.Commodity === key) return false;
      if (type === 'TradingHub' && metadata.TradingHub === key) return false;
      return !(type === 'PricingBasis' && metadata.PricingBasis === key);
    });

    setLpData(newLpData);
  };

  const stats = [
    { label: 'Commodity', values: formatStatItems(uniqueCommodities) },
    { label: 'Trading Hub', values: formatStatItems(uniqueTradingHubs) },
    { label: 'Pricing Basis', values: formatStatItems(uniquePricingBasis) }
  ];

  return {
    totalColumns: uniqueColumns.size,
    stats,
    updatedLast24Hours,
    deleteFromLpData
  };
};

export default useLaunchpadStats;
