import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { Box, Typography } from '@mui/material';
import { ChartSeries } from '../../useChartSeries';
import LegendDropdown from './LegendDropdown';
import useStockChart from './useStockChart';
import { DEFAULT_CHART_HEIGHT, TABLE_ROW_HEIGHT_PX } from 'utils/constants';
import Loader from 'components/Loader';

const StockChart = ({
  chartSeries,
  height,
  error,
  loading,
  isLaunchpad
}: {
  chartSeries: ChartSeries[] | undefined;
  height?: number;
  error: boolean;
  loading: boolean;
  isLaunchpad?: boolean;
}) => {
  const { options, chartObject, chartComponentRef } = useStockChart({
    chartSeries,
    height,
    isLaunchpad
  });

  const chartContainerHeight = height ? height * TABLE_ROW_HEIGHT_PX : DEFAULT_CHART_HEIGHT;

  if (error)
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: chartContainerHeight
        }}
      >
        <Typography color='info'>
          An error occurred while retrieving your data. Our team is looking into it.
        </Typography>
        <Typography color='info'>Please try again shortly.</Typography>
      </Box>
    );

  if (loading)
    return (
      <Box sx={{ height: chartContainerHeight }}>
        <Loader />
      </Box>
    );

  if (chartSeries) {
    return (
      <Box className='stock-chart'>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'stockChart'}
          options={options}
          ref={chartComponentRef}
        />
        {chartObject?.series && <LegendDropdown chart={chartObject} />}
      </Box>
    );
  }
};
export default StockChart;
