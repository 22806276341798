import { useEffect, useState, KeyboardEvent, ChangeEvent } from 'react';
import { Box, TextField, Button, ButtonBase, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { emailValidator } from 'utils/validators';
import { useAppDispatch, useAppSelector } from 'hooks/redux-hooks';
import { loginGX, resetStatus, selectMemoizedAuthState } from 'slices/authSlice';
import { getGxTokenFromStorage } from 'utils/commonFunctions';
import GoLogo from 'components/GoLogo';
import DefaultLayout from 'layouts/DefaultLayout';
import { sendHubspotInit } from 'components/Tracking/hubspotTrackingListener';

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [logoExpanded, setLogoExpanded] = useState<boolean>(false);

  const { status } = useAppSelector(selectMemoizedAuthState);
  const loading = status === 'loading';

  useEffect(() => {
    resetPage();
    handleEntrance();
    return () => resetPage();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (getGxTokenFromStorage()) setLoggedIn(true);
    }, 4000);

    if (loggedIn) clearInterval(intervalId);

    return () => clearInterval(intervalId);
  }, [loggedIn, status]);

  const handleEntrance = () => {
    setLogoExpanded(true);
    const gxToken = getGxTokenFromStorage();
    if (gxToken) navigate('/markets');
  };

  const identifyUserInHubSpot = (email: string) => {
    if (window._hsq) {
      sendHubspotInit(window.location.pathname, email);
    }
  };

  const validateEmail = () => {
    const errorMessage = emailValidator(email);
    if (errorMessage) setError(errorMessage);
    return !errorMessage;
  };

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleLogin();
  };

  const handleLogin = async () => {
    if (validateEmail()) {
      identifyUserInHubSpot(email);
      dispatch(loginGX(email)).then(response => {
        if (response.type.includes('rejected')) {
          setError('An error occurred. Please contact the General Index support team.'); // TODO
        }
      });
    }
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    if (error) setError('');
    setEmail(e.target.value);
  };

  const resetPage = () => {
    setEmail('');
    setError('');
    dispatch(resetStatus());
  };

  const fontStyle = { mb: 2, fontSize: 24 };

  const renderActionComponent = () => {
    if (loggedIn)
      return (
        <>
          <Typography sx={fontStyle}>You've been logged in.</Typography>
          <Typography sx={fontStyle}>You can now close this window.</Typography>
        </>
      );

    if (status === 'fulfilled' && !error)
      return (
        <>
          <Typography sx={fontStyle}>We've sent a link to your email address</Typography>
          <Typography sx={{ mb: 4, fontSize: 24, fontWeight: 700 }}>{email}</Typography>
          <Typography sx={{ mb: 3, fontSize: 24 }}>Please check your inbox.</Typography>
          <Typography>
            If you don't see the email, please check your spam folder or&nbsp;
            <ButtonBase
              sx={{ textDecoration: 'underline', fontSize: 16, mb: '3px' }}
              onClick={resetPage}
            >
              try resending
            </ButtonBase>
            .
          </Typography>
        </>
      );

    return (
      <>
        <Typography sx={fontStyle}>Welcome to GX Go!</Typography>
        <Typography sx={{ mb: 3 }}>To login, please enter your email address</Typography>
        <Box
          minHeight={84}
          width={360}
        >
          <TextField
            variant='filled'
            fullWidth
            type='email'
            autoComplete='email'
            id='email'
            label='Email'
            name='email'
            /* eslint-disable-next-line jsx-a11y/no-autofocus */
            autoFocus
            onKeyDown={handleOnKeyDown}
            value={email}
            onChange={handleChangeEmail}
            error={!!error}
            helperText={error}
            disabled={loading}
          />
        </Box>
        <Button
          fullWidth
          variant='contained'
          sx={{
            mt: 1,
            mb: 2,
            color: 'background.default',
            fontWeight: 'bold',
            fontSize: 18
          }}
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? 'Logging in...' : 'Login'}
        </Button>
        <Typography>
          New to GX Go?{' '}
          <Link
            href='https://www.general-index.com/contact-us'
            target='_blank'
            rel='noopener noreferrer'
            color='primary.main'
            sx={{ textDecoration: 'none' }}
          >
            Request a demo
          </Link>{' '}
          today.
        </Typography>
      </>
    );
  };

  return (
    <DefaultLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: 'fit-content',
          mt: '8%'
        }}
      >
        <GoLogo open={logoExpanded} />
        <Box sx={{ maxWidth: '550px', mt: 3 }}>{renderActionComponent()}</Box>
      </Box>
    </DefaultLayout>
  );
};

export default Login;
