import { Box, Button, Link, Typography } from '@mui/material';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import useCodeSnippetPreview from './useCodeSnippetPreview';

const CodeSnippetPreviewScreen = ({
  exportType,
  from,
  to,
  period,
  periodNumber,
  includeMetadata
}: {
  exportType: 'all' | 'range' | 'dates';
  from?: string;
  to?: string;
  period?: string;
  periodNumber?: string;
  includeMetadata: boolean;
}) => {
  const { copyToClipboard, loading, error, fileContent } = useCodeSnippetPreview(
    exportType,
    includeMetadata,
    from,
    to,
    period,
    periodNumber
  );

  return (
    <Box sx={{ minWidth: 470, maxWidth: 735 }}>
      <Box sx={{ ml: 2, display: 'flex' }}>
        {
          <Button
            variant='contained'
            onClick={copyToClipboard}
            disabled={!fileContent || loading}
            sx={{ textTransform: 'uppercase', mr: 2 }}
          >
            Copy To Clipboard
          </Button>
        }
      </Box>
      {error && (
        <Typography
          color='error'
          sx={{ p: 2, fontSize: '14px' }}
        >
          An error occurred while fetching data. Please try again.
        </Typography>
      )}
      {loading ? (
        <Typography sx={{ p: 2, fontSize: '14px' }}>Fetching data, please wait...</Typography>
      ) : (
        <Box
          sx={{
            whiteSpace: 'pre',
            fontFamily: 'monospace',
            fontSize: '14px',
            mt: 3
          }}
        >
          <Typography sx={{ p: 2, fontSize: '12px' }}>
            For more information on the GX SDK, please visit our{' '}
            <Link
              href={`https://docs.g-x.co/#python-sdk`}
              target='_blank'
              rel='noopener noreferrer'
              underline='hover'
            >
              documentation site
            </Link>
            .
          </Typography>
          <SyntaxHighlighter
            language='python'
            style={nightOwl}
            customStyle={{ backgroundColor: '#1A224D', margin: 0, height: 487 }}
          >
            {fileContent}
          </SyntaxHighlighter>
        </Box>
      )}
    </Box>
  );
};

export default CodeSnippetPreviewScreen;
