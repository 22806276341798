import { Box, Paper, Typography } from '@mui/material';
import { useEffect } from 'react';
import useCatalog, { getAmountLabel } from './useCatalog';
import CatalogTable from './CatalogTable';
import CatalogSkeleton from './CatalogSkeleton';
import { useAppSelector } from 'hooks/redux-hooks';
import MainLayout from 'layouts/MainLayout';
import { selectCatalogStatus } from 'slices/catalogSlice';
import Tabs from 'components/Tabs';

const Catalog = () => {
  const status = useAppSelector(selectCatalogStatus);
  const {
    filterOpen,
    setFilterOpen,
    columnFiltersWithCounts,
    rows,
    setFilters,
    filteredData,
    table,
    activeTab,
    setActiveTab,
    isForwardCurves,
    filters,
    showDiscontinued,
    setShowDiscontinued,
    expandedData,
    setExpandedData,
    columnVisibility,
    specialFilters,
    setSpecialFilters,
    showSelectedSymbols,
    setShowSelectedSymbols
  } = useCatalog();

  const catalogTabs = [
    {
      name: 'Spot',
      label: 'Spot'
    },
    {
      name: 'Forward curves',
      label: 'Forward Curves'
    }
  ];

  useEffect(() => {
    document.title = 'GX Go | Catalog';
    return () => {
      document.title = 'GX Go';
    };
  });

  return (
    <MainLayout>
      {status !== 'loading' && status !== 'idle' ? (
        <>
          <Box sx={{ display: 'flex', width: '100%', mt: 3 }}>
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'flex-end', width: 390 }}>
              <Typography
                variant='h2'
                sx={{ mr: 3 }}
                noWrap
              >
                Catalog
              </Typography>
              <Typography
                noWrap
                variant='h6'
              >
                {getAmountLabel(filteredData, isForwardCurves, expandedData)}
              </Typography>
            </Box>
            <Tabs
              tabs={catalogTabs}
              minTabWidth={300}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </Box>
          <Paper sx={{ mt: 3 }}>
            <CatalogTable
              filterOpen={filterOpen}
              setFilterOpen={setFilterOpen}
              columnFiltersWithCounts={columnFiltersWithCounts}
              rows={rows}
              filters={filters}
              setFilters={setFilters}
              filteredData={filteredData}
              table={table}
              isForwardCurves={isForwardCurves}
              expandedData={expandedData}
              setExpandedData={setExpandedData}
              showDiscontinued={showDiscontinued}
              setShowDiscontinued={setShowDiscontinued}
              columnVisibility={columnVisibility}
              specialFilters={specialFilters}
              setSpecialFilters={setSpecialFilters}
              showSelectedSymbols={showSelectedSymbols}
              setShowSelectedSymbols={setShowSelectedSymbols}
            />
          </Paper>
        </>
      ) : (
        <CatalogSkeleton />
      )}
    </MainLayout>
  );
};

export default Catalog;
