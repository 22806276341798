import { Routes, Route } from 'react-router-dom';
import ProtectedLayout from 'layouts/ProtectedLayout';
import Home from 'pages/Home';
import Login from 'pages/Login';
import Insights from 'pages/Insights';
import Profile from 'pages/Profile';
import NotFound from 'pages/NotFound';
import Catalog from 'pages/Catalog';
import Launchpad from 'pages/Launchpad';

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={<Home />}
      />
      <Route
        path='/token'
        element={<Home />}
      />
      <Route
        path='/login'
        element={<Login />}
      />
      <Route element={<ProtectedLayout />}>
        <Route
          path='markets'
          element={<Insights />}
        />
        <Route
          path='markets/:insightId'
          element={<Insights />}
        />
        <Route
          path='catalog'
          element={<Catalog />}
        />
        <Route
          path='data-hub'
          element={<Launchpad />}
        />
        <Route
          path='/profile'
          element={<Profile />}
        />
      </Route>
      <Route
        path='*'
        element={<NotFound />}
      />
    </Routes>
  );
};

export default AppRoutes;
