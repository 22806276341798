import { useRef } from 'react';
import { Box, Collapse, Typography } from '@mui/material';
import logo from 'assets/images/gxLogo.svg';
import betaImage from 'assets/images/beta.svg';

const GoLogo = ({
  size = 64,
  open = true,
  onClick,
  beta
}: {
  size?: number;
  open?: boolean;
  onClick?: () => void | null;
  beta?: boolean;
}) => {
  const containerRef = useRef<HTMLElement>(null);
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: onClick ? 'pointer' : 'initial'
      }}
      onClick={onClick}
      ref={containerRef}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'flex-start'
        }}
      >
        <Box
          component='img'
          src={logo}
          alt='GX'
          sx={{
            display: 'block',
            height: `${size}px`
          }}
        />
        {beta && (
          <Box
            component='img'
            src={betaImage}
            alt='Beta'
            sx={{
              position: 'absolute',
              bottom: '-23px',
              left: '50%',
              transform: 'translateX(-50%)',
              height: `${size * 0.35}px`
            }}
          />
        )}
      </Box>
      <Collapse
        orientation='horizontal'
        timeout={1000}
        in={open}
      >
        <Typography
          fontSize={`${size}px`}
          color='primary'
          marginLeft={0.5}
          lineHeight={1}
          fontWeight={500}
          sx={{ userSelect: 'none' }}
        >
          Go
        </Typography>
      </Collapse>
    </Box>
  );
};

export default GoLogo;
