import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import useLaunchpad from './useLaunchpad';
import LaunchpadSummary from './Summary';
import ActionBar from './ActionBar';
import LaunchpadPreview from './Preview';
import LaunchpadGraph from './Graph';
import LaunchpadExport from './Export';
import PremiumFeatureScreen from './PremiumFeatureScreen';
import MainLayout from 'layouts/MainLayout';
import Tabs from 'components/Tabs';
import TabPanel from 'components/TabPanel';

const Launchpad = () => {
  const { activeTab, setActiveTab, lpData, setLpData, chartSeries, loading, isLaunchpadEmpty } =
    useLaunchpad();
  const disabled = isLaunchpadEmpty || loading;

  const launchpadTabs = [
    {
      name: 'Summary',
      disabled
    },
    {
      name: 'Preview',
      disabled
    },
    {
      name: 'Chart',
      disabled
    },
    {
      name: 'Export',
      disabled
    }
  ];

  const returnTabPanelContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <LaunchpadPreview
            loading={loading}
            lpData={lpData}
          />
        );
      case 2:
        return <LaunchpadGraph templateChartSeries={chartSeries} />;
      case 3:
        return <LaunchpadExport />;
      default:
        return (
          <LaunchpadSummary
            loading={loading}
            lpData={lpData}
            setLpData={setLpData}
            chartSeries={chartSeries}
            setActiveTab={setActiveTab}
            isLaunchpadEmpty={isLaunchpadEmpty}
          />
        );
    }
  };

  useEffect(() => {
    document.title = 'GX Go | Data Hub';
    return () => {
      document.title = 'GX Go';
    };
  }, []);

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', width: '100%', mt: 3 }}>
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'flex-end', width: 290 }}>
          <Typography
            variant='h2'
            sx={{ mr: 3 }}
            noWrap
          >
            Data Hub
          </Typography>
          {/*<Typography*/}
          {/*  noWrap*/}
          {/*  variant='h6'*/}
          {/*>*/}
          {/*  New Data Hub*/}
          {/*</Typography>*/}
        </Box>
        <Tabs
          tabs={launchpadTabs}
          minTabWidth={200}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </Box>
      <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
        <PremiumFeatureScreen />
        <ActionBar
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {launchpadTabs.map(({ name }, index) => (
          <TabPanel
            key={name}
            index={index}
            value={activeTab}
          >
            {returnTabPanelContent()}
          </TabPanel>
        ))}
      </Box>
    </MainLayout>
  );
};

export default Launchpad;
