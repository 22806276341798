import { Box, Button, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { DeleteForeverOutlined } from '@mui/icons-material';
import { LpData } from '../useLaunchpad';
import useLaunchpadStats from './useLaunchpadStats';
import Loader from 'components/Loader';
import { RootState } from 'store';

const LaunchpadStats = ({
  loading,
  lpData,
  setLpData
}: {
  loading: boolean;
  lpData: LpData;
  setLpData: (newLpData: LpData) => void;
}) => {
  const { totalColumns, stats, updatedLast24Hours, deleteFromLpData } = useLaunchpadStats(
    lpData,
    setLpData
  );

  return (
    <Paper
      sx={{
        py: 1,
        px: 2,
        mb: 2,
        fontSize: 12,
        height: 141,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 700,
              borderBottom: '1px solid',
              py: 0.5,
              borderColor: 'text.primary'
            }}
          >
            Data Hub ({totalColumns} Symbols; {updatedLast24Hours} updated in last 24 hours )
          </Typography>
          <Box sx={{ display: 'flex', height: 97, overflow: 'auto' }}>
            {stats.map(({ label, values }, index) => (
              <>
                <Box
                  sx={{ display: 'flex', width: '33%', p: 1, pl: 0, '&: last-child': { pl: 0.7 } }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      key={label}
                      sx={{
                        fontSize: 14,
                        fontWeight: 700,
                        mb: 0.5
                      }}
                    >
                      {label}:{' '}
                    </Typography>
                    {values.map(({ name, count }) => (
                      <Box sx={{ display: 'flex', mb: 1 }}>
                        <Typography
                          key={name}
                          sx={{ fontSize: 12 }}
                        >
                          {name} ({count})
                        </Typography>
                        {/*<Button*/}
                        {/*  variant='outlined'*/}
                        {/*  sx={{*/}
                        {/*    p: 0,*/}
                        {/*    backgroundColor: '#f8f8f833',*/}
                        {/*    border: 'none',*/}
                        {/*    fontSize: 9,*/}
                        {/*    ml: 1,*/}
                        {/*    height: 16*/}
                        {/*  }}*/}
                        {/*  onClick={() =>*/}
                        {/*    deleteFromLpData(*/}
                        {/*      name,*/}
                        {/*      label.replace(/\s+/g, '') as*/}
                        {/*        | 'TradingHub'*/}
                        {/*        | 'PricingBasis'*/}
                        {/*        | 'Commodity'*/}
                        {/*    )*/}
                        {/*  }*/}
                        {/*>*/}
                        {/*  <DeleteForeverOutlined sx={{ fontSize: '4px' }} />*/}
                        {/*  Remove*/}
                        {/*</Button>*/}
                      </Box>
                    ))}
                    {index !== stats.length - 1 && (
                      <Box
                        sx={{
                          position: 'absolute',
                          bottom: 10,
                          left: `${32 * (index + 1)}%`,
                          width: '1px',
                          height: 84,
                          backgroundColor: 'info.dark'
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </>
            ))}
          </Box>
        </>
      )}
    </Paper>
  );
};

export default LaunchpadStats;
