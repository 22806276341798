import { useEffect, useRef, useState } from 'react';
import { ChartSeries } from '../../useChartSeries';
import useFullScreenOptions from './useFullScreenOptions';

const useFullScreenChart = ({ chartSeries }: { chartSeries: ChartSeries[] | undefined }) => {
  const height = window.innerHeight - 260;
  const { createOptions } = useFullScreenOptions({ height });

  const getOptions = () => {
    const options = chartSeries && createOptions(chartSeries);
    return options;
  };
  const options = chartSeries ? getOptions() : null;

  const chartComponentRef = useRef(null);
  const [chartObject, setChartObject] = useState<Highcharts.Chart>();
  useEffect(() => {
    if (chartComponentRef.current) {
      const chartRef: any = chartComponentRef.current;
      setChartObject(chartRef.chart);
    }
  }, [chartComponentRef.current]);

  return { chartObject, options, chartComponentRef };
};

export default useFullScreenChart;
