import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/redux-hooks';

import { getInsights } from 'slices/insightsSlice';
import { RootState } from 'store';

const useInsights = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { insightsList, status: insightListStatus } = useSelector(
    (state: RootState) => state.insightsList
  );
  const { userConfig, status: configStatus } = useSelector((state: RootState) => state.userConfig);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (!params?.insightId && insightListStatus === 'fulfilled' && configStatus !== 'loading') {
      let insightId = insightsList[0]?.insightId;
      if (configStatus === 'fulfilled') {
        const defaultInsight = userConfig.defaultInsight.value;

        if (defaultInsight.length && checkInsightInList(defaultInsight)) {
          insightId = defaultInsight;
        }
      }
      navigate(`/markets/${insightId}`);
    }
  }, [insightsList, configStatus]);

  useEffect(() => {
    fetchInsights();
    setActiveTab(0);
  }, [params]);

  const fetchInsights = async () => {
    if (params?.insightId) {
      dispatch(getInsights(params.insightId));
    }
  };

  const checkInsightInList = (defaultInsight: string) => {
    return insightsList.some(insight => insight.insightId === defaultInsight);
  };
  return {
    activeTab,
    setActiveTab
  };
};

export default useInsights;
