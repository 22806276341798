import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  styled,
  TextField,
  Typography
} from '@mui/material';
import useGenerationBody from './useGenerationBody';
import { DisableableButton } from 'components/DisableableButton';

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& label.Mui-focused': {
    color: theme.palette.text.primary
  },
  '& div.MuiInput-underline::after': {
    borderBottom: `2px solid ${theme.palette.text.primary}`
  }
}));

const PreGenerationBody = ({ handleClose }: { handleClose: () => void }) => {
  const { handleChange, apiKeyName, checkNameProvided, onGenerate } = useGenerationBody();
  return (
    <>
      <DialogContent sx={{ paddingBottom: 2 }}>
        <Box sx={{ paddingTop: 2, display: 'flex', alignItems: 'flex-end' }}>
          <Typography
            variant='subtitle2'
            sx={{ mr: 3 }}
          >
            Name
          </Typography>
          <StyledTextField
            id='key-label'
            label='Type name here...'
            variant='standard'
            value={apiKeyName}
            onChange={handleChange}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          sx={{ mr: 3, pr: 0, minWidth: '40px' }}
          variant='text'
          onClick={handleClose}
        >
          Cancel
        </Button>
        <DisableableButton
          variant='contained'
          disabledProp={!checkNameProvided()}
          clickCallback={() => onGenerate()}
        >
          Generate
        </DisableableButton>
      </DialogActions>
    </>
  );
};

export default PreGenerationBody;
