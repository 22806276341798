import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux-hooks';
import { loginTrip } from 'slices/authSlice';
import { RootState } from 'store';
import { getGxTokenFromStorage } from 'utils/commonFunctions';

const ProtectedLayout = () => {
  const gxToken = getGxTokenFromStorage();

  const dispatch = useAppDispatch();
  const { tripToken } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!tripToken && gxToken) {
      dispatch(loginTrip(gxToken));
    }
  }, [gxToken, tripToken]);

  if (!gxToken) {
    return (
      <Navigate
        replace
        to='/login'
      />
    );
  }

  if (tripToken) {
    return <Outlet />;
  }
};

export default ProtectedLayout;
