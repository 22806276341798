import { ReactElement, useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module-v2';
import { history } from '../../store';
import useUserDetails from 'hooks/useUserAccess';

const gtmId = import.meta.env.VITE_GTM_ID;

function initializeGTM() {
  console.log('initializing gtm');
  const tagManagerArgs = {
    gtmId: gtmId,
    dataLayerName: 'InsightDataLayer'
  };
  TagManager.initialize(tagManagerArgs);
}

export function GoogleTrackingLister({ children }: { readonly children: ReactElement }) {
  const { getUserEmail } = useUserDetails();
  const userEmail = getUserEmail();
  const initialized = useRef(false);

  function sendPageView(page: string, userEmail: string) {
    const userDataLayer = {
      dataLayer: {
        userId: userEmail,
        page: page
      },
      dataLayerName: 'InsightDataLayer'
    };
    TagManager.dataLayer(userDataLayer);
  }

  useEffect(() => {
    if (gtmId && userEmail) {
      initializeGTM();
      sendPageView(window.location.pathname, userEmail ?? '');
      initialized.current = true;
    }
    return history.listen(({ action }) => {
      if (action === 'PUSH') sendPageView(window.location.pathname, userEmail ?? '');
    });
  }, [userEmail, gtmId]);

  return <>{children}</>;
}
