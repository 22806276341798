import { DialogTitle } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import useGenerationBody from './useGenerationBody';
import PostGenerationBody from './PostGenerationBody';
import PreGenerationBody from './PreGenerationBody';
import SmallDialog from 'components/SmallDialog';
const KeyDialog = ({
  open,
  setOpen
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { generated, handleClose } = useGenerationBody();
  return (
    <SmallDialog
      open={open}
      handleClose={() => handleClose(setOpen)}
    >
      <DialogTitle sx={{ width: 590, p: 3 }}>Generate new API token</DialogTitle>
      {generated ? (
        <PostGenerationBody handleClose={() => handleClose(setOpen)} />
      ) : (
        <PreGenerationBody handleClose={() => handleClose(setOpen)} />
      )}
    </SmallDialog>
  );
};
export default KeyDialog;
