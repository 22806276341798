import { Box, Paper } from '@mui/material';
import { CommoditiesTableTabType } from '../types';
import CommoditiesTableTab from './CommoditiesTableTab';
import useCommoditiesTable from './useCommoditiesTable';
import TableSearch from './TableSearch';
import Tabs from 'components/Tabs';
import TabPanel from 'components/TabPanel';

const CommoditiesTable = ({
  tabs,
  layout
}: {
  tabs: CommoditiesTableTabType[];
  layout?: { fullWidth?: boolean; height?: number };
}) => {
  const { activeTab, setActiveTab, searchValue, handleChangeSearch, highlightedRowId } =
    useCommoditiesTable(tabs);
  const { fullWidth, height } = layout || {};

  return (
    <Box
      component={Paper}
      sx={theme => ({
        width: fullWidth ? '100%' : 'calc(50% - 12px)',
        [theme.breakpoints.down(1350)]: {
          width: '100%'
        }
      })}
    >
      <Box sx={{ height: 48, mb: 1.8 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tabs
            height={48}
            tabs={tabs.map(({ name }) => ({ name }))}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <TableSearch
            searchValue={searchValue}
            handleChangeSearch={handleChangeSearch}
          />
        </Box>
      </Box>
      {tabs.map(({ name, sections }, index) => (
        <TabPanel
          key={name}
          index={index}
          value={activeTab}
        >
          <CommoditiesTableTab
            sections={sections}
            highlightedRowId={highlightedRowId}
            height={height}
          />
        </TabPanel>
      ))}
    </Box>
  );
};

export default CommoditiesTable;
