import { useEffect, ReactElement, useRef } from 'react';
import 'react-hubspot-tracking-code-hook';
import { history } from '../../store';
import useUserDetails from 'hooks/useUserAccess';

const trackingID = import.meta.env.VITE_HUBSPOT_ID;

export const sendHubspotInit = (page: string, email: string) => {
  const _hsq = (window._hsq = window._hsq || []);
  _hsq.push(['identify', { email: email }]);
  _hsq.push(['setContentType', 'standard-page']);
  _hsq.push(['setPath', page]);
  _hsq.push(['trackPageView']);
};

export function HubspotTrackingLister({ children }: { readonly children: ReactElement }) {
  const initialized = useRef(false);

  const { getUserEmail } = useUserDetails();
  const email = getUserEmail();
  const sendPageView = (page: string) => {
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['setPath', page]);
    _hsq.push(['trackPageView']);
  };

  useEffect(() => {
    if (trackingID) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'hs-script-loader';
      script.async = true;
      script.defer = true;
      script.src = '//js.hs-scripts.com/' + trackingID + '.js';
      document.body.appendChild(script);

      if (email) {
        sendHubspotInit(window.location.pathname, email);
        initialized.current = true;
      }
      return history.listen(({ action }) => {
        if (action === 'PUSH') sendPageView(window.location.pathname);
      });
    }
  }, [email, trackingID]);

  return <>{children}</>;
}
