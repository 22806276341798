import Paper from '@mui/material/Paper';
import { Box, Button, Typography } from '@mui/material';
import KeyDialog from './KeyDialog';
import useTokenGeneration from './useTokenGeneration';
import ApiKeyTable from './Table';

const TokenGenerationComponent = () => {
  const { apiKeys, open, setOpen, error } = useTokenGeneration();
  return (
    <Box
      component={Paper}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: 3,
        width: '100%'
      }}
    >
      <KeyDialog
        open={open}
        setOpen={setOpen}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant='body2'>Token Generation</Typography>
        <Button
          variant={'contained'}
          sx={{ pl: 2, pr: 2 }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          + Generate new token
        </Button>
      </Box>
      {error || <ApiKeyTable apiKeys={apiKeys} />}
    </Box>
  );
};

export default TokenGenerationComponent;
