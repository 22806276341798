import { ChangeEvent } from 'react';
import { SettingsOutlined } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
  MenuItem
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Popper from 'components/Popper';
import Checkbox from 'components/Checkbox';

const ExportSettings = ({
  includeMetadata,
  setIncludeMetadata,
  exportType,
  setExportType,
  from,
  setFrom,
  to,
  setTo,
  period,
  setPeriod,
  periodNumber,
  setPeriodNumber
}: {
  includeMetadata: boolean;
  setIncludeMetadata: (newValue: boolean) => void;
  exportType: 'all' | 'range' | 'dates';
  setExportType: (newType: 'all' | 'range' | 'dates') => void;
  from: Date | null;
  setFrom: (newValue: Date | null) => void;
  to: Date | null;
  setTo: (newValue: Date | null) => void;
  period: string;
  setPeriod: (newValue: string) => void;
  periodNumber: string;
  setPeriodNumber: (newValue: string) => void;
}) => {
  const handleChangeRadio = (e: ChangeEvent<HTMLInputElement>) => {
    setExportType(e.target.value as 'all' | 'range' | 'dates');
  };

  const fromError = !from && exportType === 'dates';

  return (
    <Popper
      sx={{ ml: 3 }}
      placement='bottom-start'
      buttonVariant='outlined'
      buttonContent={
        <>
          <SettingsOutlined sx={{ fontSize: 16, mr: 1 }} />
          Export Settings
        </>
      }
    >
      <Box
        sx={{
          minWidth: 400,
          borderBottom: '1px solid',
          borderLeft: '1px solid',
          borderColor: 'primary.main',
          display: 'flex',
          flexDirection: 'column',
          fontSize: '14px'
        }}
      >
        <Accordion expanded>
          <AccordionSummary
            sx={{
              '&.Mui-expanded': {
                backgroundColor: 'secondary.main'
              }
            }}
          >
            Date settings
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: 'secondary.main',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <FormControl>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                value={exportType}
                onChange={handleChangeRadio}
              >
                <FormControlLabel
                  value='all'
                  control={<Radio />}
                  label='All'
                />
                <Box sx={{ display: 'flex', alignItems: 'center', height: 50 }}>
                  <FormControlLabel
                    value='range'
                    control={<Radio />}
                    label='Last'
                  />
                  <TextField
                    size='small'
                    variant='filled'
                    slotProps={{
                      htmlInput: {
                        maxLength: 2,
                        inputMode: 'numeric'
                      }
                    }}
                    value={periodNumber}
                    onChange={e => setPeriodNumber(e.target.value.replace(/\D/g, ''))}
                    sx={{
                      ml: 2,
                      width: 45,
                      '& .MuiInputBase-input': {
                        p: '7px 14px'
                      }
                    }}
                  />
                  <Select
                    MenuProps={{
                      disablePortal: true,
                      onClick: e => e.preventDefault()
                    }}
                    size='small'
                    sx={{
                      ml: 2,
                      '& .MuiInputBase-input': {
                        p: '5.5px 14px',
                        backgroundColor: '#272d48'
                      },
                      '& .MuiMenu-paper': {
                        top: '108px!important',
                        left: '141px!important'
                      }
                    }}
                    value={period}
                    variant='filled'
                    onChange={e => setPeriod(e.target.value)}
                  >
                    <MenuItem
                      sx={{ fontSize: '14px' }}
                      value='d'
                    >
                      Days
                    </MenuItem>
                    <MenuItem
                      sx={{ fontSize: '14px' }}
                      value='m'
                    >
                      Months
                    </MenuItem>
                    <MenuItem
                      sx={{ fontSize: '14px' }}
                      value='y'
                    >
                      Years
                    </MenuItem>
                  </Select>
                </Box>
                <FormControlLabel
                  value='dates'
                  control={<Radio />}
                  label='From'
                />
                <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={from}
                      onChange={newValue => setFrom(newValue)}
                      slotProps={{
                        textField: {
                          error: fromError,
                          helperText: fromError ? 'Please select a valid date' : '',
                          variant: 'filled',
                          sx: {
                            mr: 1,
                            '.MuiInputBase-input': {
                              pt: 1
                            }
                          }
                        }
                      }}
                    />
                    <DatePicker
                      value={to}
                      onChange={newValue => setTo(newValue)}
                      slotProps={{
                        textField: {
                          variant: 'filled',
                          sx: {
                            '.MuiInputBase-input': {
                              pt: 1
                            }
                          }
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <FormControlLabel
          sx={{ m: 0, py: 1, px: 2 }}
          control={
            <Checkbox
              checked={includeMetadata}
              onChange={() => setIncludeMetadata(!includeMetadata)}
            />
          }
          label='Include metadata'
        />
      </Box>
    </Popper>
  );
};

export default ExportSettings;
