import Highcharts from 'highcharts/highstock';
import { FormControl, MenuItem, OutlinedInput, Select, Box } from '@mui/material';

import useLegendDropdown from './useLegendDropdown';

const LegendItem = ({ series }: { series: any }) => {
  return (
    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Box
        style={{
          width: '8px',
          height: '2px',
          backgroundColor: series.color,
          display: 'inline-block',
          marginRight: 4
        }}
      ></Box>
      <Box
        style={{
          maxWidth: 550,
          width: '100%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }}
      >
        {series.userOptions?.metadata?.hideName
          ? series.name
          : series.name + ' - ' + series.userOptions?.metadata?.field}
      </Box>
    </Box>
  );
};

const LegendDropdown = ({ chart }: { chart: Highcharts.Chart }) => {
  const { seriesName, allChartSeries, handleLegendItemClick } = useLegendDropdown({ chart });
  return (
    <Box sx={{ minWidth: 120, pr: 5, pl: 1, pb: 2 }}>
      <FormControl fullWidth>
        <Select
          id='legend-select'
          multiple
          size='small'
          sx={{
            height: 34,
            '.MuiSelect-select': {
              paddingLeft: 1
            }
          }}
          value={seriesName || allChartSeries}
          onChange={e => handleLegendItemClick(e, allChartSeries)}
          input={<OutlinedInput inputProps={{ pl: 0, height: 34 }} />}
          renderValue={(seriesArray: any) => {
            return <LegendItem series={seriesArray[0]} />;
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            MenuListProps: {
              sx: {
                padding: 0
              }
            },
            PaperProps: {
              sx: {
                border: '1px solid #E84E0F',
                borderBottom: 0,
                borderLeft: 0
              }
            }
          }}
        >
          {allChartSeries?.map((series: any) => {
            return (
              <MenuItem
                sx={{ '&.MuiButtonBase-root': { pl: 1 } }}
                key={series.index}
                value={series}
              >
                <LegendItem series={series} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default LegendDropdown;
