import { Box, Button, ButtonBase, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import useLaunchpadExport from './useLaunchpadExport';
import ExportSettings from './ExportSettings';
import { exportOptionsGroups } from './exportList';
import { selectUserConfig } from 'slices/userConfigSlice';
import { useAppSelector } from 'hooks/redux-hooks';
import ExportPreview from 'pages/Launchpad/Export/Preview';
import SmallDialog from 'components/SmallDialog';
import SuccessScreen from 'pages/Launchpad/Export/SuccessScreen';
import LoadingScreen from 'pages/Launchpad/Export/LoadingScreen';

const LaunchpadExport = () => {
  const navigate = useNavigate();
  const {
    userConfig: {
      timezone: { label }
    }
  } = useAppSelector(selectUserConfig);

  const { launchpadSymbols } = useSelector((state: RootState) => state.launchpadSelector);

  const {
    exportData,
    exportApiRequestString,
    exportCurlRequestString,
    loading,
    exported,
    setExported,
    includeMetadata,
    setIncludeMetadata,
    exportType,
    setExportType,
    from,
    setFrom,
    to,
    setTo,
    period,
    setPeriod,
    periodNumber,
    setPeriodNumber,
    previewOptionId,
    setPreviewOptionId
  } = useLaunchpadExport();

  const isExcelPreview = ['excel?type=simple', 'excel?type=advanced'].includes(previewOptionId);

  if (loading && isExcelPreview) return <LoadingScreen />;

  if (exported && isExcelPreview)
    return (
      <SuccessScreen
        setExported={setExported}
        setPreviewOptionId={setPreviewOptionId}
      />
    );

  return (
    <Box sx={{ display: 'flex', height: 820 }}>
      <Paper sx={{ flexGrow: 1, p: 2, height: 'fit-content' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h5'>Data Hub ({launchpadSymbols.length} symbols)</Typography>
          <ExportSettings
            {...{
              includeMetadata,
              setIncludeMetadata,
              exportType,
              setExportType,
              from,
              setFrom,
              to,
              setTo,
              period,
              setPeriod,
              periodNumber,
              setPeriodNumber
            }}
          />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={theme => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '65%',
              [theme.breakpoints.down('lg')]: {
                width: 'initial',
                minWidth: 280
              }
            })}
          >
            <Box sx={{ display: 'flex', alignItems: 'baseline', my: 2 }}>
              Timezone: {label}{' '}
              <ButtonBase
                sx={{
                  ml: 2,
                  color: 'primary.main'
                }}
                onClick={() => navigate('/profile')}
              >
                Change in Settings
              </ButtonBase>
            </Box>
            {exportOptionsGroups.map(({ groupName, GroupIcon, options }) => (
              <Box
                key={groupName}
                sx={{ my: 2 }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <GroupIcon
                    fontSize='small'
                    sx={{ mr: 1 }}
                  />
                  {groupName}
                </Box>
                <Grid
                  container
                  spacing={2}
                >
                  {options.map(
                    ({
                      id,
                      label,
                      icon,
                      outlined,
                      disabled
                    }: {
                      id: string;
                      label?: string;
                      icon: string;
                      outlined?: boolean;
                      disabled?: boolean;
                    }) => (
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={4}
                        key={id}
                      >
                        <Button
                          disabled={disabled}
                          variant={outlined ? 'outlined' : 'contained'}
                          fullWidth
                          sx={{
                            height: 33,
                            display: 'flex',
                            justifyContent: 'flex-start',
                            backgroundColor: outlined ? '' : 'secondary.main',
                            '&:hover': {
                              backgroundColor: outlined ? '' : 'secondary.main'
                            }
                          }}
                          onClick={() => setPreviewOptionId(id)}
                        >
                          <Box
                            component='img'
                            src={icon}
                            sx={{ mr: 1 }}
                          />
                          {label}
                        </Button>
                      </Grid>
                    )
                  )}
                </Grid>
              </Box>
            ))}
          </Box>
          <ExportPreview
            previewOptionId={previewOptionId}
            exportApiRequestString={exportApiRequestString}
            exportCurlRequestString={exportCurlRequestString}
            exportData={exportData}
            includeMetadata={includeMetadata}
            from={from}
            to={to}
            period={period}
            periodNumber={periodNumber}
            exportType={exportType}
          />
        </Box>
      </Paper>
      {!isExcelPreview && (
        <SmallDialog
          open={loading || exported}
          handleClose={() => {
            setExported(false);
            setPreviewOptionId('');
          }}
        >
          <Box sx={{ backgroundColor: 'background.default', p: 4, minWidth: 400 }}>
            {loading ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress
                  size={20}
                  sx={{ mr: 2 }}
                />
                Your request is being processed...
              </Box>
            ) : (
              <>
                Your request has been processed.
                <br />
                We'll be in touch shortly.
                <Box sx={{ textAlign: 'right', mt: 3 }}>
                  <Button
                    variant='contained'
                    onClick={() => {
                      setExported(false);
                      setPreviewOptionId('');
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </SmallDialog>
      )}
    </Box>
  );
};

export default LaunchpadExport;
