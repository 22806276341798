import { Box, Button, Link, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import JsonCsvPreviewScreen from './JsonCsvPreviewScreen';
import CodeSnippetPreviewScreen from './CodeSnippetPreviewScreen';
import { exportOptionsGroups } from 'pages/Launchpad/Export/exportList';

const ExportPreview = ({
  previewOptionId,
  exportApiRequestString,
  exportCurlRequestString,
  exportData,
  includeMetadata,
  from,
  to,
  period,
  periodNumber,
  exportType
}: {
  previewOptionId: string;
  exportApiRequestString: string;
  exportCurlRequestString: string;
  exportData: (type: string) => Promise<void>;
  includeMetadata: boolean;
  exportType: 'all' | 'range' | 'dates';
  from?: string;
  to?: string;
  period?: string;
  periodNumber?: string;
}) => {
  const findOptionById = () => {
    for (const group of exportOptionsGroups) {
      const foundOption = group.options.find(({ id }) => id === previewOptionId);
      if (foundOption) {
        return { ...foundOption, groupName: group.groupName };
      }
    }
    return null;
  };

  const { id, actionHeading, previewOptions, previewImg, groupName } = findOptionById() || {};

  const copyToClipboard = () => {
    navigator.clipboard.writeText(exportApiRequestString);
    enqueueSnackbar('Code snippet copied to clipboard!', {
      variant: 'success',
      autoHideDuration: 5000
    });
  };

  const handlePrimaryButtonClick = () => {
    if (groupName === 'Developer Tools') copyToClipboard();
    else exportData(previewOptionId);
  };

  const returnPrimaryButtonLabel = () => {
    if (id === 'csv' || id === 'json' || id === 'python') return null;
    switch (groupName) {
      case 'File Exports':
        return 'Download';
      case 'Developer Tools':
        return 'Copy to Clipboard';
      case 'Cloud Analytics Platforms':
      case 'BI Tools':
      case 'Market Data Integrations':
      default:
        return 'Enable Access';
    }
  };

  return (
    <Paper
      sx={{
        ml: 2,
        mt: 2,
        height: 708,
        flexGrow: 1,
        width: '35%',
        minWidth: 470,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'secondary.main',
        overflow: 'hidden'
      }}
    >
      {id ? (
        <>
          <Box>
            <Box sx={{ p: 2 }}>
              <Typography fontSize={24}>{actionHeading}</Typography>
              <List>
                {previewOptions?.map((option: string) => (
                  <ListItem
                    sx={{ p: 0 }}
                    key={option}
                  >
                    <Box sx={{ backgroundColor: 'text.primary', width: 4, height: 4, mr: 1 }} />
                    <ListItemText primary={option} />
                  </ListItem>
                ))}
              </List>
              {returnPrimaryButtonLabel() && (
                <Box sx={{ mt: 2 }}>
                  <Button
                    variant='contained'
                    onClick={handlePrimaryButtonClick}
                    sx={{ textTransform: 'uppercase' }}
                  >
                    {returnPrimaryButtonLabel()}
                  </Button>
                  {id === 'gx_api' && (
                    <Button
                      component={Link}
                      target='_blank'
                      rel='noopener noreferrer'
                      variant='contained'
                      href={exportApiRequestString}
                      sx={{ textTransform: 'uppercase', ml: 2 }}
                    >
                      Launch
                    </Button>
                  )}
                </Box>
              )}
            </Box>
            {(id === 'json' || id === 'csv') && (
              <JsonCsvPreviewScreen
                isCSV={id === 'csv'}
                exportType={exportType}
                includeMetadata={includeMetadata}
                exportCurlRequestString={exportCurlRequestString}
                from={from}
                to={to}
                period={period}
                periodNumber={periodNumber}
              />
            )}
            {id === 'python' && (
              <CodeSnippetPreviewScreen
                exportType={exportType}
                includeMetadata={includeMetadata}
                from={from}
                to={to}
                period={period}
                periodNumber={periodNumber}
              />
            )}
            {id === 'gx_api' && (
              <Paper
                sx={{
                  m: 2,
                  p: 3,
                  backgroundColor: '#1A224D',
                  fontFamily: 'monospace',
                  wordBreak: 'break-all'
                }}
              >
                {exportApiRequestString}
              </Paper>
            )}
            {id === 'gx_api' && (
              <Typography sx={{ p: 2, fontSize: '12px' }}>
                For more information on the GX API, please visit our{' '}
                <Link
                  href='https://docs.g-x.co/#introduction'
                  target='_blank'
                  rel='noopener noreferrer'
                  underline='hover'
                >
                  documentation site
                </Link>
                .
              </Typography>
            )}
          </Box>
          {previewImg && (
            <Box
              component='img'
              src={previewImg}
              alt={actionHeading}
              sx={{ height: `100%` }}
            />
          )}
        </>
      ) : (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        >
          Preview
        </Box>
      )}
    </Paper>
  );
};

export default ExportPreview;
