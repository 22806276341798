import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { DashboardOutlined, ListAltOutlined } from '@mui/icons-material';

const buttonStyles = {
  width: 250,
  display: 'flex',
  justifyContent: 'flex-start',
  backgroundColor: 'info.light',
  '&:hover': {
    backgroundColor: 'info.dark'
  }
};

const EmptyLaunchpadScreen = () => (
  <Box
    sx={{
      mt: 12,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Typography
      variant='h4'
      sx={{ fontWeight: 700 }}
    >
      Data Hub is empty
    </Typography>
    <Typography sx={{ my: 4 }}>Get started by adding data through these links</Typography>
    <Button
      sx={buttonStyles}
      variant='contained'
      component={Link}
      to='/markets'
      startIcon={<DashboardOutlined />}
    >
      Markets
    </Button>
    <Button
      sx={{
        mt: 4,
        ...buttonStyles
      }}
      variant='contained'
      component={Link}
      to='/catalog'
      startIcon={<ListAltOutlined sx={{ fontSize: '20px!important' }} />}
    >
      Catalog
    </Button>
  </Box>
);

export default EmptyLaunchpadScreen;
