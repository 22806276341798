import { Paper } from '@mui/material';
import { TABLE_ROW_HEIGHT_PX } from 'utils/constants';
import StockChart from 'pages/Insights/InsightsComponents/ChartsBlock/StockChartsBlock/StockChart';
import useChartSeries from 'pages/Insights/InsightsComponents/ChartsBlock/useChartSeries';

const LaunchpadGraph = ({
  templateChartSeries,
  isSummary
}: {
  templateChartSeries: any;
  isSummary?: boolean;
}) => {
  const calculateChartHeight = (): number => {
    const VIEWPORT_HEIGHT = window.innerHeight;
    const EXTRA_PADDING = isSummary ? 540 : 330;
    return Math.floor((VIEWPORT_HEIGHT - EXTRA_PADDING) / TABLE_ROW_HEIGHT_PX);
  };

  const { chartSeries, error, loading } = useChartSeries(templateChartSeries);

  return (
    <Paper sx={{ p: 2, height: `calc(100vh - ${isSummary ? 454 : 235}px)` }}>
      <StockChart
        isLaunchpad
        error={error}
        loading={loading}
        chartSeries={chartSeries?.slice(0, isSummary ? 4 : 6)}
        height={calculateChartHeight()}
      />
    </Paper>
  );
};

export default LaunchpadGraph;
