import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import SymbolSubRow from './SubRow';
import useSelectionFunctions from 'components/SymbolSelector/useSelectionFunctions';
import { CommoditiesTableRowType } from 'pages/Insights/InsightsComponents/types';
import { SelectedSymbol } from 'slices/symbolSelectorSlice';

const CollapsedRow = ({
  open,
  row,
  lastSymbol,
  diffSymbol
}: {
  open: boolean;
  row: CommoditiesTableRowType;
  lastSymbol: SelectedSymbol;
  diffSymbol: SelectedSymbol;
}) => {
  const { toggleSymbolInSelection, isSymbolSelected } = useSelectionFunctions();
  return (
    <TableCell
      className='collapsed-table-row'
      sx={theme => ({
        pb: 0,
        pt: 0,
        borderLeft: open ? `2px solid ${theme.palette.primary.main}` : 'none'
      })}
      colSpan={7}
    >
      <Collapse
        in={open}
        timeout='auto'
        unmountOnExit
      >
        <Box sx={{ ml: 3.5 }}>
          <Table
            size='small'
            aria-label='purchases'
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ pr: 2, width: '15%' }}></TableCell>
                <TableCell sx={{ pr: 2 }}>
                  <Typography
                    sx={theme => ({ fontSize: 10, color: theme.palette.table.infoRowText })}
                  >
                    Code
                  </Typography>
                </TableCell>
                <TableCell sx={{ pr: 2 }}>
                  <Typography
                    sx={theme => ({ fontSize: 10, color: theme.palette.table.infoRowText })}
                  >
                    Title
                  </Typography>
                </TableCell>
                <TableCell sx={{ pr: 2 }}>
                  <Typography
                    sx={theme => ({ fontSize: 10, color: theme.palette.table.infoRowText })}
                  >
                    PeriodAbs
                  </Typography>
                </TableCell>
                <TableCell
                  align='right'
                  sx={{ pr: 2 }}
                >
                  <Typography
                    sx={theme => ({ fontSize: 10, color: theme.palette.table.infoRowText })}
                  >
                    Currency
                  </Typography>
                </TableCell>
                <TableCell
                  align='right'
                  sx={{ pr: 2 }}
                >
                  <Typography
                    sx={theme => ({ fontSize: 10, color: theme.palette.table.infoRowText })}
                  >
                    Units
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <SymbolSubRow
                symbol={row.symbol}
                metadata={row.metadata}
                periodAbs={row.periodAbs}
                label={'Last'}
                checked={isSymbolSelected(lastSymbol)}
                onChange={() => {
                  toggleSymbolInSelection(lastSymbol, isSymbolSelected(lastSymbol));
                }}
              />
              <SymbolSubRow
                symbol={row.diffSymbol}
                metadata={row.diffMetadata}
                periodAbs={row.diffPeriodAbs}
                label={'Diff'}
                checked={isSymbolSelected(diffSymbol)}
                onChange={() => {
                  toggleSymbolInSelection(diffSymbol, isSymbolSelected(diffSymbol));
                }}
              />
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </TableCell>
  );
};

export default CollapsedRow;
