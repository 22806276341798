import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  useReactTable
} from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';
import { RootState } from '../../store';
import {
  defaultVisibilityState,
  energyTransitionModules,
  forwardCurvesColumns,
  oilOnlyModules,
  spotColumns
} from './constants';
import { useAppDispatch } from 'hooks/redux-hooks';
import { CatalogItem, getCatalog } from 'slices/catalogSlice';
import useSelectionFunctions from 'components/SymbolSelector/useSelectionFunctions';
import { SHOW_SELECTED_URL_PARAM } from 'utils/constants';

export type SpecialFiltersType = {
  dataAccessOnly: boolean;
  oilOnly: boolean;
  energyTransitionOnly: boolean;
};
export const getAmountLabel = (
  filteredData: CatalogItem[] | undefined,
  isForwardCurves: boolean,
  expandedData: boolean
) => {
  const codesLabel = `${filteredData?.length.toLocaleString('en-US')} codes`;
  if (isForwardCurves) return codesLabel;
  else {
    if (expandedData) return `${filteredData?.length.toLocaleString('en-US')} symbols`;
    return codesLabel;
  }
};

const useCatalog = () => {
  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState<number>(0);

  const isForwardCurves = !!activeTab;
  const { isSymbolSelected } = useSelectionFunctions();
  const [showSelectedSymbols, setShowSelectedSymbols] = useState(false);
  const [showDiscontinued, setShowDiscontinued] = useState<boolean>(false);
  const [expandedData, setExpandedData] = useState<boolean>(false);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<{ [key: string]: string[] }>({});
  const [sorting, setSorting] = useState(isForwardCurves ? [] : [{ id: 'Alias', desc: false }]);
  const [specialFilters, setSpecialFilters] = useState<SpecialFiltersType>({
    dataAccessOnly: false,
    oilOnly: false,
    energyTransitionOnly: false
  });
  const [columnVisibility, setColumnVisibility] = useState(defaultVisibilityState);
  const { catalog } = useSelector((state: RootState) => state.catalog);
  const [urlSearchParams] = useSearchParams();

  useEffect(() => {
    if (urlSearchParams.get(SHOW_SELECTED_URL_PARAM)) {
      setShowSelectedSymbols(Boolean(urlSearchParams.get(SHOW_SELECTED_URL_PARAM)));
    }
  }, [urlSearchParams]);

  useEffect(() => {
    fetchCatalog();
  }, [activeTab]);

  const fetchCatalog = async () => {
    dispatch(getCatalog(isForwardCurves));
  };

  useEffect(() => {
    clearState();
  }, [activeTab]);

  const clearState = () => {
    setFilters({});
    setSpecialFilters({
      dataAccessOnly: false,
      oilOnly: false,
      energyTransitionOnly: false
    });
    setFilterOpen(false);
    setColumnVisibility(
      isForwardCurves
        ? prevState => ({
            ...prevState,
            TimeRef: true,
            PeriodType: true
          })
        : defaultVisibilityState
    );
    setExpandedData(false);
  };

  const columns = isForwardCurves ? forwardCurvesColumns : spotColumns;

  const filterBySelection = (catalog: CatalogItem[]) => {
    return catalog.filter(catalogItem => isSymbolSelected(catalogItem.Symbol));
  };
  const filteredData = useMemo(() => {
    if (!catalog) return [];

    const filteredBySelection: CatalogItem[] =
      showSelectedSymbols && !isForwardCurves ? filterBySelection(catalog) : catalog;

    const filteredByStatus = filteredBySelection.filter(
      catalogItem => showDiscontinued || catalogItem.Status !== 'Archive'
    );

    const filteredByFilters = filteredByStatus.filter(catalogItem =>
      Object.keys(filters).every(column => {
        const selectedValues = filters[column];
        if (!selectedValues || selectedValues.length === 0) return true;
        if (column === 'IndexModule') {
          return selectedValues.every(selectedFilterValue =>
            catalogItem[column].includes(selectedFilterValue)
          );
        }
        return selectedValues.includes(catalogItem[column]);
      })
    );
    const filteredBySpecialFilters = filteredByFilters.filter(catalogItem => {
      const { IndexModule, Last, Change } = catalogItem;

      const dataAccessCondition = !specialFilters.dataAccessOnly || (!!Last && !!Change);

      const oilOnlyCondition =
        !specialFilters.oilOnly || oilOnlyModules.some(module => IndexModule.includes(module));

      const energyTransitionCondition =
        !specialFilters.energyTransitionOnly ||
        energyTransitionModules.some(module => IndexModule.includes(module));

      return dataAccessCondition && oilOnlyCondition && energyTransitionCondition;
    });

    if (expandedData || isForwardCurves) return filteredBySpecialFilters;

    const uniqueRowsByCode: { [code: string]: CatalogItem } = {};

    filteredBySpecialFilters.forEach(catalogItem => {
      const existingItem = uniqueRowsByCode[catalogItem.Code];
      if (!existingItem) {
        uniqueRowsByCode[catalogItem.Code] = catalogItem;
      } else {
        if (
          catalogItem.TimeRef > existingItem.TimeRef ||
          (catalogItem.TimeRef === existingItem.TimeRef && catalogItem.PeriodType === 'Prompt') ||
          (catalogItem.PeriodType === existingItem.PeriodType && catalogItem.Period === 1)
        ) {
          uniqueRowsByCode[catalogItem.Code] = catalogItem;
        }
      }
    });
    return Object.values(uniqueRowsByCode);
  }, [
    catalog,
    filters,
    showDiscontinued,
    expandedData,
    columnVisibility,
    specialFilters,
    showSelectedSymbols
  ]);

  const columnFiltersWithCounts = useMemo(() => {
    const getCountedLabels = (
      key: string,
      joined?: boolean
    ): { label: string; count: number }[] => {
      const counts: { [label: string]: number } = {};

      filteredData?.forEach(catalogItem => {
        if (joined) {
          const labels: string[] = catalogItem[key].split(',');
          labels.forEach(label => {
            countOccurence(counts, label);
          });
        } else {
          const label = catalogItem[key];
          countOccurence(counts, label);
        }
      });

      return Object.entries(counts)
        .map(([label, count]) => ({ label, count }))
        .sort((a, b) => b.count - a.count);
    };

    return {
      Commodity: getCountedLabels('Commodity'),
      TradingHub: getCountedLabels('TradingHub'),
      DeliveryBasis: getCountedLabels('DeliveryBasis'),
      PricingBasis: getCountedLabels('PricingBasis'),
      Units: getCountedLabels('Units'),
      Currency: getCountedLabels('Currency'),
      Frequency: getCountedLabels('Frequency'),
      IndexModule: getCountedLabels('IndexModule', true)
    };
  }, [filteredData]);

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    state: {
      columnVisibility,
      sorting
    },
    onColumnVisibilityChange: setColumnVisibility as OnChangeFn<any>,
    onSortingChange: setSorting
  });

  const { rows } = table.getRowModel();

  return {
    filterOpen,
    setFilterOpen,
    columnFiltersWithCounts,
    rows,
    setFilters,
    filteredData,
    table,
    activeTab,
    setActiveTab,
    isForwardCurves,
    filters,
    showDiscontinued,
    setShowDiscontinued,
    expandedData,
    setExpandedData,
    columnVisibility,
    specialFilters,
    setSpecialFilters,
    showSelectedSymbols,
    setShowSelectedSymbols
  };
};

export default useCatalog;
function countOccurence(counts: { [label: string]: number }, label: string) {
  if (counts[label]) {
    counts[label]++;
  } else {
    counts[label] = 1;
  }
}
