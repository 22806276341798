import { Box, Link, Paper } from '@mui/material';

const SearchAndStreamBar = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
    }}
  >
    <Paper
      sx={{
        width: '100%',
        height: '41px',
        color: 'text.secondary',
        display: 'flex',
        alignItems: 'center',
        px: 2
      }}
    >
      <Link
        href='https://www.general-index.com/ice-hou-white-paper'
        target='_blank'
        rel='noopener noreferrer'
        color='text.secondary'
        sx={{ textDecoration: 'none' }}
      >
        White Paper with ICE. The Texas Crude Triangle: Driving Global Crude.&nbsp;&nbsp;
        <u>Read More...</u>
      </Link>
    </Paper>
  </Box>
);

export default SearchAndStreamBar;
