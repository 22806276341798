import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { transformPickerDateToISO } from 'utils/commonFunctions';
import { SelectedSymbol } from 'slices/symbolSelectorSlice';

const DEFAULT_GROUP_NAME = 'Prod_Indexes';
const useCodeSnippetPreview = (
  exportType: 'all' | 'range' | 'dates',
  includeMetadata: boolean,
  from?: string,
  to?: string,
  period?: string,
  periodNumber?: string
) => {
  const { launchpadSymbols } = useSelector((state: RootState) => state.launchpadSelector);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [fileContent, setFileContent] = useState<string>('');

  useEffect(() => {
    getLaunchpadIndexes();
  }, [launchpadSymbols, from, to, includeMetadata, period, periodNumber, exportType]);

  const getLaunchpadIndexes = async () => {
    setLoading(true);
    setError(false);

    try {
      const fromValue =
        exportType === 'range'
          ? `${periodNumber}${period}`
          : exportType === 'dates' && from
            ? transformPickerDateToISO(from, 'start')
            : 'all';

      const toValue = exportType === 'dates' && to ? transformPickerDateToISO(to, 'end') : '';

      const results = await fetch('/export/examples/python_export_script_template.txt');
      const pythonScriptTemplate = await results.text();

      // Define placeholder replacements
      const replacements = {
        symbols: JSON.stringify(transformSymbols(launchpadSymbols), undefined, 4),
        rangeStart: fromValue,
        rangeEnd: toValue,
        withMetadata: includeMetadata.toString()
      };

      const pythonScript = replacePlaceholders(pythonScriptTemplate, replacements);
      setFileContent(pythonScript);
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(fileContent);
    enqueueSnackbar('Code snippet copied to clipboard!', {
      variant: 'success',
      autoHideDuration: 5000
    });
  };

  // Function to transform the array into the desired JSON format
  function transformSymbols(selectedSymbols: SelectedSymbol[]) {
    return {
      symbols: selectedSymbols.map(symbol => ({
        group: DEFAULT_GROUP_NAME,
        key: {
          code: symbol.Code,
          periodType: symbol.PeriodType,
          timeRef: symbol.TimeRef,
          period: symbol.Period
        }
      }))
    };
  }

  /**
   * Replace placeholders in a template string with provided values.
   * @param template The template string containing placeholders (e.g., {startFrom}).
   * @param replacements A map of placeholder keys and their replacement values.
   * @returns The processed string with placeholders replaced.
   */
  function replacePlaceholders(template: string, replacements: Record<string, string>): string {
    return template.replace(/\{(\w+)}/g, (match, key) => {
      return key in replacements ? replacements[key] : match; // Replace only if the key exists
    });
  }

  return { copyToClipboard, loading, error, fileContent };
};

export default useCodeSnippetPreview;
