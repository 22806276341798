import { useState } from 'react';
import { useAppDispatch } from 'hooks/redux-hooks';

import { ApiKeyOutput, deleteApiKey } from 'slices/apiKeyListSlice';

const useApiKeyTable = () => {
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [apiKey, setApiKey] = useState<ApiKeyOutput>({
    id: -1,
    lastThreeCharacters: '',
    label: '',
    creationDate: ''
  });
  const openConfirmModal = (apiKey: ApiKeyOutput) => {
    setOpen(true);
    setApiKey(apiKey);
  };
  const deleteKey = () => {
    dispatch(deleteApiKey(apiKey.id)).then(() => {
      setOpen(false);
    });
  };
  return {
    open,
    setOpen,
    apiKey,
    openConfirmModal,
    deleteKey
  };
};

export default useApiKeyTable;
