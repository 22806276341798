import { useEffect, useRef, useState } from 'react';
import { ChartSeries } from '../../useChartSeries';
import useStockOptions from './useStockOptions';

const useStockChart = ({
  chartSeries,
  height,
  isLaunchpad
}: {
  chartSeries: ChartSeries[] | undefined;
  height?: number;
  isLaunchpad?: boolean;
}) => {
  const { createOptions } = useStockOptions({
    height,
    isLaunchpad
  });

  const options = chartSeries && createOptions(chartSeries);
  const chartComponentRef = useRef(null);
  const [chartObject, setChartObject] = useState<Highcharts.Chart>();

  useEffect(() => {
    if (chartComponentRef.current) {
      const chartRef: any = chartComponentRef.current;
      setChartObject(chartRef.chart);
    }
  }, [chartComponentRef, chartSeries]);
  return { options, chartObject, chartComponentRef };
};

export default useStockChart;
