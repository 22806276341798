import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MAX_SELECTED_SYMBOLS } from 'utils/constants';
import { SelectedSymbol } from 'slices/symbolSelectorSlice';

type LaunchpadSelectionState = {
  launchpadSymbols: SelectedSymbol[];
  launchpadLimitReached: boolean;
};

const initialState: LaunchpadSelectionState = {
  launchpadSymbols: [],
  launchpadLimitReached: false
};

const launchpadSlice = createSlice({
  name: 'symbolSelector',
  initialState,
  reducers: {
    setLaunchpadSelection(state, action: PayloadAction<SelectedSymbol[]>) {
      const newSymbolsSet = new Set(action.payload.map(symbol => JSON.stringify(symbol)));

      state.launchpadSymbols = [
        ...state.launchpadSymbols.filter(symbol => !newSymbolsSet.has(JSON.stringify(symbol))),
        ...action.payload
      ];

      state.launchpadLimitReached = state.launchpadSymbols.length > MAX_SELECTED_SYMBOLS;
    },
    clearLaunchpad(state) {
      state.launchpadSymbols = [];
      state.launchpadLimitReached = false;
    }
  }
});

export const { setLaunchpadSelection, clearLaunchpad } = launchpadSlice.actions;

export default launchpadSlice.reducer;
