import { ReactElement, useEffect } from 'react';
import posthog from 'posthog-js';
import {} from 'utils/constants';
import useUserDetails from 'hooks/useUserAccess';

const posthogKey = import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY;
const posthogHost = import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST;

function initializePostHog() {
  console.log('initializing postHog');
  if (posthogKey && posthogHost) {
    posthog.init(posthogKey, {
      api_host: posthogHost
    });
  }
}

export function PostHogTrackingLister({ children }: { readonly children: ReactElement }) {
  const { getUserEmail, getUserAccess, getHsObjectId } = useUserDetails();
  const email = getUserEmail();
  const userAccessLevel = getUserAccess();
  const hubSpotUserId = getHsObjectId();
  useEffect(() => {
    if (posthog && email) {
      initializePostHog();
      posthog.identify(hubSpotUserId || email, {
        hubSpotUserId: hubSpotUserId,
        email,
        userAccessLevel: userAccessLevel
      });
    }
  }, [posthog, email]);

  return <>{children}</>;
}
