import { useSelector } from 'react-redux';
import { UserAccess } from 'slices/authSlice';
import { RootState } from 'store';

const useUserDetails = () => {
  const { userAccess, userEmail, hsObjectId } = useSelector((state: RootState) => state.auth);
  const checkUserHasPremium = () => {
    return userAccess ? [UserAccess.Premium, UserAccess.Internal].includes(userAccess) : false;
  };
  const checkUserIsFree = () => {
    return userAccess ? userAccess === UserAccess.Free : false;
  };

  const getUserEmail = () => {
    return userEmail;
  };

  const getUserAccess = () => {
    return userAccess;
  };

  const getHsObjectId = () => {
    return hsObjectId;
  };
  return { checkUserHasPremium, checkUserIsFree, getUserEmail, getUserAccess, getHsObjectId };
};

export default useUserDetails;
