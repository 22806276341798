import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import {
  DashboardOutlined,
  ExpandLess,
  ExpandMore,
  ListAltOutlined,
  PersonOutlined,
  Launch
} from '@mui/icons-material';

import ProfileDropdown from './ProfileDropdown';
import InsightsDropdown from './InsightsDropdown';

const NavBar = ({ open }: { open: boolean }) => {
  const [openItem, setOpenItem] = useState<number | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname.split('/')[1];

  useEffect(() => {
    if (!open) setOpenItem(null);
  }, [open]);

  const toggleItem = (index: number) => {
    setOpenItem(openItem === index ? null : index);
  };

  const navigationBarItems = [
    {
      name: 'Markets',
      icon: <DashboardOutlined />,
      link: 'markets',
      // component: <InsightsDropdown />,
      onClick: toggleItem
    },
    {
      name: 'Catalog',
      icon: <ListAltOutlined />,
      link: 'catalog',
      onClick: () => navigate('/catalog')
    },
    {
      name: 'Data Hub',
      icon: <Launch />,
      link: 'data-hub',
      onClick: () => navigate('/data-hub')
    },
    {
      name: 'Profile',
      icon: <PersonOutlined />,
      link: 'profile',
      component: <ProfileDropdown />,
      onClick: toggleItem
    }
  ];

  return (
    <>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {navigationBarItems.map(({ name, icon, onClick, component, link }, index) => {
          const InnerComponent = !!component && (
            <Collapse
              in={openItem === index}
              timeout='auto'
              unmountOnExit
            >
              {component}
            </Collapse>
          );

          const isLastItem = index + 1 === navigationBarItems.length;
          const isSelected = link === currentRoute;

          return (
            <ListItem
              key={name}
              disablePadding
              sx={{ display: 'block', mb: 1, '&:last-child': { mt: 'auto', mb: 0 } }}
            >
              {isLastItem && InnerComponent}
              <ListItemButton
                selected={isSelected}
                sx={{
                  minHeight: 56,
                  px: 3,
                  justifyContent: open ? 'initial' : 'center',
                  '&.Mui-selected': {
                    backgroundColor: 'background.paper',
                    borderBottom: '2px solid',
                    borderBottomColor: 'primary.main'
                  }
                }}
                onClick={() => onClick(index)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: 'center',
                    mr: open ? 3 : 'auto'
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: isSelected ? 600 : 500
                      }}
                    >
                      {name}
                      {component &&
                        (openItem === index ? (
                          <ExpandLess sx={{ ml: 1, mb: '-3px' }} />
                        ) : (
                          <ExpandMore sx={{ ml: 1, mb: '-3px' }} />
                        ))}
                    </Box>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
              {!isLastItem && InnerComponent}
              {open && name === 'Markets' && <InsightsDropdown />}
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default NavBar;
