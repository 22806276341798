import { ReactNode } from 'react';
import { Box } from '@mui/material';

const DefaultLayout = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      backgroundImage: 'linear-gradient(244deg, #121836 0%, #070818 99.27%) !important',
      width: 'fit-content',
      minWidth: '100%'
    }}
  >
    <Box
      sx={{
        minHeight: '100vh',
        height: '100%',
        p: 4,
        minWidth: '300px',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      {children}
    </Box>
  </Box>
);

export default DefaultLayout;
