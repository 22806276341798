import {
  CommoditiesTableRowType,
  CommoditiesTableSectionType
} from 'pages/Insights/InsightsComponents/types';
import { createSelectedSymbol, SelectedSymbol } from 'slices/symbolSelectorSlice';
import useSelectionFunctions from 'components/SymbolSelector/useSelectionFunctions';

const useCommoditiesSelection = () => {
  const {
    addSymbolsArrayToSelection,
    addAllSymbolsToSelection,
    allSymbolsSelected,
    removeSymbolFromSelection,
    removeSymbolsFromSelection,
    addSymbolToSelection
  } = useSelectionFunctions();

  const getSymbolsFrom = (row: CommoditiesTableRowType) => {
    const symbols: Array<SelectedSymbol> = [];
    const last = createSelectedSymbol(row.symbol.key, 'Last');
    const diff = createSelectedSymbol(row.diffSymbol.key, 'Diff');
    if (last.Code) {
      symbols.push(last);
    }
    if (diff.Code) {
      symbols.push(diff);
    }
    return symbols;
  };

  const isSectionSelected = (rows: CommoditiesTableRowType[]): boolean => {
    const symbols = rows.map(row => getSymbolsFrom(row)).flatMap(symbol => symbol);
    if (symbols.length < 1) return false;
    return allSymbolsSelected(symbols);
  };

  const toggleSectionInSelection = (
    rows: CommoditiesTableRowType[],
    sectionSelected: boolean = false
  ) => {
    if (sectionSelected) removeSectionFromSelection(rows);
    else addSectionToSelection(rows);
  };

  const removeSectionFromSelection = (rows: CommoditiesTableRowType[]) => {
    for (const row of rows) {
      const last = createSelectedSymbol(row.symbol.key, 'Last');
      const diff = createSelectedSymbol(row.diffSymbol.key, 'Diff');
      removeSymbolFromSelection(last);
      removeSymbolFromSelection(diff);
    }
  };

  const addSectionToSelection = (rows: CommoditiesTableRowType[]) => {
    for (const row of rows) {
      const symbols = getSymbolsFrom(row);
      symbols.forEach(symbol => addSymbolToSelection(symbol));
    }
  };

  const toggleCodeInSelection = (row: CommoditiesTableRowType, codeSelected: boolean): void => {
    const symbols = getSymbolsFrom(row);
    if (codeSelected) {
      symbols.forEach(symbol => removeSymbolFromSelection(symbol));
    } else {
      const symbols = getSymbolsFrom(row);
      addSymbolsArrayToSelection(symbols);
    }
  };

  const getAllSymbolsInTab = (sections: CommoditiesTableSectionType[]) =>
    sections
      .map(({ rows }) => {
        return rows
          .map(row => {
            return getSymbolsFrom(row);
          })
          .flatMap(symbol => symbol);
      })
      .flatMap(symbol => symbol);

  const addAllCodesToSelection = (sections: CommoditiesTableSectionType[]) => {
    addAllSymbolsToSelection(getAllSymbolsInTab(sections));
  };

  const clearAllCodesFromSelection = (sections: CommoditiesTableSectionType[]) => {
    removeSymbolsFromSelection(getAllSymbolsInTab(sections));
  };

  const allCodesSelected = (sections: CommoditiesTableSectionType[]) => {
    if (sections.length < 1) return false;
    return sections.every(section => {
      return isSectionSelected(section.rows);
    });
  };

  return {
    toggleSectionInSelection,
    allCodesSelected,
    addAllCodesToSelection,
    clearAllCodesFromSelection,
    isSectionSelected,
    toggleCodeInSelection
  };
};

export default useCommoditiesSelection;
