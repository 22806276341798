import { Box, Button, Typography } from '@mui/material';
import useUserDetails from 'hooks/useUserAccess';

const PremiumFeatureScreen = () => {
  const { checkUserHasPremium, getUserAccess } = useUserDetails();
  if (getUserAccess() && !checkUserHasPremium())
    return (
      <>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(8px)',
            zIndex: 1000
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 'calc(50% - 150px)',
            left: 'calc(50% - 150px)',
            textAlign: 'center',
            zIndex: 1001,
            width: 300
          }}
        >
          <Typography
            variant='h4'
            sx={{ fontWeight: 700 }}
          >
            Premium Feature
          </Typography>
          <Typography sx={{ fontSize: 20, my: 3 }}>
            Unlock full access to advanced analytics and real-time data
          </Typography>
          <Button
            variant='contained'
            href='https://www.general-index.com/contact-us'
            target='_blank'
            rel='noopener noreferrer'
          >
            Upgrade to Premium
          </Button>
        </Box>
      </>
    );
  return null;
};

export default PremiumFeatureScreen;
