import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import httpInstances from 'api/axiosInstance';

export type ApiKeyOutput = {
  prefixedRawApiKey?: string;
  id: number;
  lastThreeCharacters: string;
  label: string;
  creationDate: string;
  lastUsed?: string;
};

type ApiKeyListState = {
  status: 'idle' | 'loading' | 'failed' | 'fulfilled' | 'creating';
  error: string | null;
  generated: boolean;
  apiKeyOutputs: ApiKeyOutput[];
};

type ErrorResponse = {
  message: string;
};

const initialState: ApiKeyListState = {
  status: 'idle',
  error: null,
  generated: false,
  apiKeyOutputs: []
};

export const createApiKey = createAsyncThunk(
  'createApiKey',
  async (label: string, { rejectWithValue }) => {
    try {
      const body = { label };
      const { data } = await httpInstances.axiosGxInstance.post('/auth/apikey', body);
      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

export const deleteApiKey = createAsyncThunk(
  'deleteApiKey',
  async (id: number, { rejectWithValue }) => {
    try {
      const { status } = await httpInstances.axiosGxInstance.delete('/auth/apikey/' + id);
      if (status === 204) {
        return id;
      } else {
        rejectWithValue("Couldn't deleted apiKey with Id" + id);
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

export const getApiKeys = createAsyncThunk('getApiKeys', async (_, { rejectWithValue }) => {
  try {
    const { data } = await httpInstances.axiosGxInstance.get(`/auth/apikey/user`);
    return data.apiKeyOutputs;
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const errorResponse = error.response.data;
      return rejectWithValue(errorResponse);
    }
    throw error;
  }
});

const apiKeyListSlice = createSlice({
  name: 'apiKeyList',
  initialState,
  reducers: {
    resetGenerated(state) {
      state.generated = false;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getApiKeys.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getApiKeys.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.apiKeyOutputs = action.payload;
      })
      .addCase(getApiKeys.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Request failed';
        } else {
          state.error = action.error.message || 'Request failed';
        }
      })
      .addCase(createApiKey.pending, state => {
        state.status = 'creating';
        state.generated = false;
      })
      .addCase(createApiKey.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        const temp = [...state.apiKeyOutputs];
        temp.push(action.payload);
        state.apiKeyOutputs = temp;
        state.generated = true;
      })
      .addCase(createApiKey.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Request failed';
        } else {
          state.error = action.error.message || 'Request failed';
        }
      })
      .addCase(deleteApiKey.pending, state => {
        state.status = 'loading';
      })
      .addCase(deleteApiKey.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        let temp = [...state.apiKeyOutputs];
        temp = temp.filter(apiKey => {
          return apiKey.id !== action.payload;
        });
        state.apiKeyOutputs = temp;
      })
      .addCase(deleteApiKey.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Request failed';
        } else {
          state.error = action.error.message || 'Request failed';
        }
      });
  }
});
export const { resetGenerated } = apiKeyListSlice.actions;
export default apiKeyListSlice.reducer;
