import { forwardRef } from 'react';
import { SnackbarContent, CustomContentProps } from 'notistack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CardContent, Theme } from '@mui/material';
import useResponseMessage from './useResponseMessage';

const ResponseMessage = forwardRef<HTMLDivElement, CustomContentProps>(
  ({ id, variant, ...props }, ref) => {
    const { handleDismiss, messages, icons, borderColours } = useResponseMessage();

    const styles = (theme: Theme) => ({
      display: 'flex',
      borderLeft: `4px solid ${borderColours(theme)[variant]}`,
      backgroundColor: '#1A224D',
      minWidth: 372,
      justifyContent: 'space-between',
      color: 'text.primary',
      height: 80,
      alignItems: 'center'
    });

    return (
      <SnackbarContent ref={ref}>
        <Card sx={styles}>
          <CardContent sx={{ display: 'flex', pl: 1, pr: 7 }}>
            {icons[variant]}
            <Typography
              sx={{ pl: 1 }}
              variant='body2'
            >
              {props.message ?? messages[variant]}
            </Typography>
          </CardContent>
          <CardActions sx={{ position: 'absolute', right: 0, top: 0 }}>
            <IconButton
              size='small'
              sx={{ p: 0 }}
              onClick={() => handleDismiss(id)}
            >
              <CloseIcon sx={{ fontSize: 16 }} />
            </IconButton>
          </CardActions>
        </Card>
      </SnackbarContent>
    );
  }
);

ResponseMessage.displayName = 'ResponseMessage';

export default ResponseMessage;
