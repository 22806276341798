import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { useAppDispatch } from 'hooks/redux-hooks';
import { Option, saveUserConfig } from 'slices/userConfigSlice';
import { RootState } from 'store';

const useProfile = () => {
  const dispatch = useAppDispatch();

  const userConfigState = useSelector((state: RootState) => state.userConfig);

  const {
    userConfig,
    userConfig: { timezone, dateFormat }
  } = userConfigState;

  const { insightsList } = useSelector((state: RootState) => state.insightsList);
  const snackId = useRef<SnackbarKey>();
  const timezoneOptions = [
    {
      label: 'America/Chicago',
      value: 'America/Chicago'
    },
    {
      label: 'America/Denver',
      value: 'America/Denver'
    },
    {
      label: 'America/Edmonton',
      value: 'America/Edmonton'
    },
    {
      label: 'America/Los_Angeles',
      value: 'America/Los_Angeles'
    },
    {
      label: 'America/New_York',
      value: 'America/New_York'
    },
    {
      label: 'America/Toronto',
      value: 'America/Toronto'
    },
    {
      label: 'America/Vancouver',
      value: 'America/Vancouver'
    },
    {
      label: 'Asia/Dubai',
      value: 'Asia/Dubai'
    },
    {
      label: 'Asia/Riyadh',
      value: 'Asia/Riyadh'
    },
    {
      label: 'Asia/Shanghai',
      value: 'Asia/Shanghai'
    },
    {
      label: 'Asia/Singapore',
      value: 'Asia/Singapore'
    },
    {
      label: 'Asia/Tokyo',
      value: 'Asia/Tokyo'
    },
    {
      label: 'Europe/London',
      value: 'Europe/London'
    },
    {
      label: 'Europe/Paris',
      value: 'Europe/Paris'
    },
    {
      label: 'UTC',
      value: 'UTC'
    }
  ];

  const dateFormatOptions = [
    { label: '14-Mar-25', value: 'dd-MMM-yy' },
    { label: '03/14/2025', value: 'MM/dd/yyyy' },
    { label: '14/03/2025', value: 'dd/MM/yyyy' },
    { label: '2025-03-14', value: 'yyyy-MM-dd' }
  ];

  const insightsOptions = insightsList.map((insight: any) => {
    return { label: insight.metadata.displayName, value: insight.insightId };
  });

  const getDefaultInsight = () => {
    if (userConfig.defaultInsight.label.length) {
      return userConfig.defaultInsight;
    }
    const firstAvailable = insightsList[0];
    return {
      label: firstAvailable?.metadata.displayName,
      value: firstAvailable?.insightId
    };
  };
  const defaultInsight = getDefaultInsight();

  const changeUserSetting = (
    settingName: 'timezone' | 'dateFormat' | 'defaultInsight',
    userSetting: Option
  ) => {
    const config = { ...userConfigState.userConfig, [settingName]: userSetting };
    const id = enqueueSnackbar('Saving your changes, please wait.', {
      variant: 'loading',
      persist: true
    });
    snackId.current = id;
    dispatch(saveUserConfig({ userConfig: config }))
      .unwrap()
      .then(() => {
        if (snackId.current === id) {
          snackId.current = enqueueSnackbar({ variant: 'success', autoHideDuration: 5000 });
        }
      })
      .catch(() => {
        snackId.current = enqueueSnackbar('We couldn’t save your changes. Please try again.', {
          variant: 'error',
          persist: true
        });
      });
  };

  return {
    timezone,
    dateFormat,
    defaultInsight,
    changeUserSetting,
    timezoneOptions,
    dateFormatOptions,
    insightsOptions
  };
};

export default useProfile;
