import { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/redux-hooks';
import { RootState } from 'store';
import { downloadAsCsv } from 'utils/commonFunctions';
import { createApiKey, resetGenerated } from 'slices/apiKeyListSlice';

const useGenerationBody = () => {
  const [apiKeyName, setApiKeyName] = useState('');
  const dispatch = useAppDispatch();
  const listApiKeyState = useSelector((state: RootState) => state.listApiKey);
  const generated = listApiKeyState.generated;
  const handleClose = (setOpen: any) => {
    dispatch(resetGenerated());
    setOpen(false);
  };

  const exportToCsv = () => {
    const header = 'Name,Key';
    const apiKey = listApiKeyState.apiKeyOutputs[listApiKeyState.apiKeyOutputs.length - 1];
    const name = apiKey?.label;
    const token = apiKey?.prefixedRawApiKey ?? '';
    const keyRow = name + ',' + token;
    const values = [header, keyRow];
    downloadAsCsv(values);
  };

  const toClipboard = () => {
    navigator.clipboard.writeText(
      listApiKeyState.apiKeyOutputs[listApiKeyState.apiKeyOutputs.length - 1]?.prefixedRawApiKey ??
        ''
    );
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setApiKeyName(event.target.value);
  };

  const onGenerate = () => {
    dispatch(createApiKey(apiKeyName));
  };

  const checkNameProvided = () => {
    return Boolean(apiKeyName.length);
  };

  return {
    handleChange,
    apiKeyName,
    checkNameProvided,
    onGenerate,
    listApiKeyState,
    toClipboard,
    exportToCsv,
    handleClose,
    generated
  };
};

export default useGenerationBody;
