import { forwardRef } from 'react';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import LinesEllipsis from 'react-lines-ellipsis';
import { LpData } from '../useLaunchpad';
import useLaunchpadPreview from './useLaunchpadPreview';
import PreviewTooltipContent from './PreviewTooltipContent';
import DateText from 'components/DateText';
import Loader from 'components/Loader';
import Tooltip from 'components/Tooltip';
import { stockChartPalette } from 'utils/constants';

const LaunchpadPreview = ({
  lpData,
  isSummary,
  loading
}: {
  lpData: LpData;
  isSummary?: boolean;
  loading: boolean;
}) => {
  const { rows } = useLaunchpadPreview(lpData);

  if (loading) return <Loader />;

  const columns = Array.from(
    new Set(
      lpData.map(
        ({ symbol, metadata }) =>
          `${symbol.TimeRef}-${symbol.Period}-${symbol.PeriodType}-${symbol.Code}-${metadata.Title}`
      )
    )
  ).map((columnKey, index) => {
    const [timeRef, period, periodType, code, title] = columnKey.split('-');
    return {
      key: columnKey,
      displayComponent: (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Tooltip
            variant='secondary'
            title={<PreviewTooltipContent {...{ timeRef, period, periodType, code }} />}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start'
              }}
            >
              <LinesEllipsis
                text={title}
                maxLine='2'
                ellipsis='...'
                trimRight
                basedOn='letters'
              />
            </Box>
          </Tooltip>
          <Box
            sx={{
              height: index < 4 && isSummary ? 4 : 2,
              mt: 0.5,
              width: '100%',
              backgroundColor: index < 4 && isSummary ? stockChartPalette[index] : 'text.primary'
            }}
          />
        </Box>
      )
    };
  });

  const VirtuosoTableComponents: TableComponents<Record<string, string>> = {
    Scroller: forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer
        sx={{ overflow: 'auto', height: '100%', width: '100%' }}
        component={Paper}
        {...props}
        ref={ref}
      />
    )),
    Table: props => (
      <Table
        {...props}
        sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}
      />
    ),
    TableHead: forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableHead
        {...props}
        ref={ref}
        sx={{ '& .MuiTableCell-root': { verticalAlign: 'bottom' } }}
      />
    )),
    TableRow,
    TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableBody
        {...props}
        ref={ref}
      />
    ))
  };

  function fixedHeaderContent() {
    return (
      <TableRow>
        <TableCell
          sx={{ width: 100, position: 'sticky', left: 0, backgroundColor: 'background.paper' }}
        />
        {columns.map(column => (
          <TableCell
            key={column.key}
            align='left'
            sx={{ backgroundColor: 'background.paper', width: 220, p: 1, px: 1.5 }}
          >
            {column.displayComponent}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  const rowContent = (
    index: number,
    row: {
      date: string;
      values: Record<string, { value: string; PeriodAbs: string; FactsheetVersion: string }>;
    }
  ) => {
    const rowStyle = { backgroundColor: index % 2 === 0 ? 'secondary.main' : 'background.paper' };

    return (
      <>
        <TableCell
          sx={{
            width: 'fit-content',
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 0,
            ...rowStyle,
            px: 2
          }}
        >
          <DateText dateString={row.date} />
        </TableCell>
        {columns.map(column => {
          const cellData = row.values[column.key];

          return (
            <TableCell
              key={column.key}
              sx={rowStyle}
              align='center'
            >
              {cellData?.value ? (
                <Tooltip
                  variant='secondary'
                  title={
                    <PreviewTooltipContent
                      code={column.key.split('-')[3]}
                      period={column.key.split('-')[1]}
                      periodType={column.key.split('-')[2]}
                      timeRef={column.key.split('-')[0]}
                      periodAbs={cellData.PeriodAbs || '-'}
                      factsheetVersion={cellData.FactsheetVersion || '-'}
                    />
                  }
                >
                  {cellData?.value}
                </Tooltip>
              ) : (
                '-'
              )}
            </TableCell>
          );
        })}
      </>
    );
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Paper sx={{ height: `calc(100vh - ${isSummary ? 298 : 232}px)`, width: '100%' }}>
        <TableVirtuoso
          data={rows}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={(index, row) => rowContent(index, row)}
        />
      </Paper>
    </Box>
  );
};

export default LaunchpadPreview;
