import { styled } from '@mui/material/styles';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import React, { ReactNode } from 'react';

interface CustomTooltipProps extends Omit<TooltipProps, 'children'> {
  variant?: 'default' | 'secondary';
  children: ReactNode;
}

const StyledTooltip = styled(({ className, ...props }: CustomTooltipProps) => (
  <Tooltip
    {...(props as TooltipProps)}
    arrow
    classes={{ popper: className }}
  />
))(({ theme, variant = 'default' }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: variant === 'default' ? theme.palette.primary.main : '#1A224D'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: variant === 'default' ? theme.palette.primary.main : '#1A224D',
    fontSize: '12px',
    borderLeft: variant === 'secondary' ? `1px solid ${theme.palette.primary.main}` : 'none'
  }
}));

const CustomTooltip = ({ children, variant = 'default', ...props }: CustomTooltipProps) => (
  <StyledTooltip
    {...props}
    variant={variant}
  >
    {children}
  </StyledTooltip>
);

export default CustomTooltip;
