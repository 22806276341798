import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { MAX_SELECTED_SYMBOLS } from 'utils/constants';
const LimitDialog = ({ handleClose, open }: { handleClose: () => void; open: boolean }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{ backgroundColor: '#2634c866' }}
      PaperProps={{
        style: {
          position: 'absolute',
          top: '130px',
          margin: 0
        }
      }}
    >
      <DialogContent sx={{ backgroundColor: 'background.default' }}>
        <DialogContentText
          sx={{ color: 'text.primary' }}
          id='alert-dialog-description'
        >
          You have tried to select more than the maximum of {MAX_SELECTED_SYMBOLS} symbols.
        </DialogContentText>
        <DialogContentText sx={{ color: 'text.primary' }}>
          Please select fewer symbols.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: 'background.default' }}>
        <Button
          sx={{ mr: 3, mb: 2, width: 150 }}
          variant='contained'
          onClick={handleClose}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LimitDialog;
