import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import httpInstances from 'api/axiosInstance';
import { UserAccess } from 'slices/authSlice';
import useUserDetails from 'hooks/useUserAccess';
export interface LpDataItem {
  transactionTime: string;
  symbol: {
    PeriodType: string;
    TimeRef: string;
    Period: string;
    Code: string;
  };
  values: {
    Mid?: string;
    Index?: string;
    PeriodAbs: string;
    FactsheetVersion: string;
  };
  metadata: {
    Title: string;
    Commodity: string;
    PricingBasis: string;
    TradingHub: string;
  };
}

export type LpData = LpDataItem[];

const useLaunchpad = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [lpData, setLpData] = useState<LpData>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const { launchpadSymbols } = useSelector((state: RootState) => state.launchpadSelector);
  const { checkUserHasPremium } = useUserDetails();
  const isLaunchpadEmpty = !launchpadSymbols.length || !checkUserHasPremium;

  useEffect(() => {
    getLaunchpadIndexes();
  }, [launchpadSymbols]);

  const getLaunchpadIndexes = async () => {
    setLoading(true);
    try {
      const requests = launchpadSymbols
        .toReversed()
        .map(({ Code, Period, PeriodType, TimeRef }) => {
          if (!Code) return null;

          return httpInstances.axiosDataInstance.get(
            `/index?code=${Code}&period=${Period}&periodType=${PeriodType}&timeRef=${TimeRef}&from=all&metadata=true`
          );
        });

      const results = await Promise.all(requests.filter(Boolean));
      const flattenedData = results.flatMap(response => response?.data);

      setLpData(flattenedData);
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getLaunchpadStats = () => {
    const stats: {
      commodities: Record<string, number>;
      tradingHubs: Record<string, number>;
      pricingBasis: Record<string, number>;
    } = {
      commodities: {},
      tradingHubs: {},
      pricingBasis: {}
    };

    lpData.forEach(({ metadata: { Commodity, PricingBasis, TradingHub } }) => {
      const commodity = Commodity || 'Unknown';
      const tradingHub = TradingHub || 'Unknown';
      const pricingBasis = PricingBasis || 'Unknown';

      stats.commodities[commodity] = (stats.commodities[commodity] || 0) + 1;
      stats.tradingHubs[tradingHub] = (stats.tradingHubs[tradingHub] || 0) + 1;
      stats.pricingBasis[pricingBasis] = (stats.pricingBasis[pricingBasis] || 0) + 1;
    });

    return stats;
  };

  const chartSeries = lpData.length
    ? launchpadSymbols.toReversed().map(key => ({
        settings: { type: 'line' as const },
        symbol: {
          key,
          group: 'Prod_Indexes'
        }
      }))
    : undefined;

  return {
    activeTab,
    setActiveTab,
    lpData,
    setLpData,
    chartSeries,
    loading,
    error,
    getLaunchpadStats,
    isLaunchpadEmpty
  };
};

export default useLaunchpad;
