import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import TokenGenerationComponent from './TokenGeneration';
import SettingChanger from './SettingChanger';
import useProfile from './useProfile';
import MainLayout from 'layouts/MainLayout';
import { RootState } from 'store';
import Loader from 'components/Loader';

const Profile = () => {
  const userConfigState = useSelector((state: RootState) => state.userConfig);

  const {
    timezone,
    changeUserSetting,
    dateFormat,
    defaultInsight,
    timezoneOptions,
    dateFormatOptions,
    insightsOptions
  } = useProfile();

  useEffect(() => {
    document.title = 'GX Go | Settings';
    return () => {
      document.title = 'GX Go';
    };
  });

  return (
    <MainLayout>
      <Typography
        variant='h2'
        sx={{ mt: 3 }}
      >
        Settings
      </Typography>
      {userConfigState.status === 'loading' ? (
        <Loader />
      ) : (
        <Stack
          sx={{ width: '100%', mt: 3 }}
          spacing={{ xs: 2, sm: 3 }}
          direction='row'
          useFlexGap
          flexWrap='wrap'
        >
          <SettingChanger
            label='Timezone'
            userSetting={timezone}
            onChange={userSetting => changeUserSetting('timezone', userSetting)}
            options={timezoneOptions}
          />
          <SettingChanger
            label='Date Format'
            userSetting={dateFormat}
            onChange={userSetting => changeUserSetting('dateFormat', userSetting)}
            options={dateFormatOptions}
          />
          <SettingChanger
            label='Default Market'
            userSetting={defaultInsight}
            onChange={userSetting => changeUserSetting('defaultInsight', userSetting)}
            options={insightsOptions}
          />
          <TokenGenerationComponent />
        </Stack>
      )}
    </MainLayout>
  );
};

export default Profile;
