import { Box, Paper, Skeleton, Stack, Typography } from '@mui/material';

const SKELETON_CONFIG = [100, 100, 60, 60];
const SKELETON_ELEMENT_HEIGHT = 280;

const InsightsSkeleton = () => (
  <Box data-testid='insights-skeleton'>
    <Typography sx={{ my: 5 }}>Loading your data, please wait...</Typography>
    <Paper sx={{ py: 2, px: 3, width: '100%', height: 56 }}>
      <Skeleton width='25%' />
    </Paper>
    <Stack
      sx={{ width: '100%', mt: 3 }}
      spacing={3}
      direction='row'
      useFlexGap
      flexWrap='wrap'
    >
      {[0, 1, 2, 3, 4, 5].map(paper => (
        <Paper
          key={paper}
          sx={theme => ({
            width: 'calc(50% - 12px)',
            minWidth: 300,
            height: SKELETON_ELEMENT_HEIGHT,
            py: 4,
            px: 3,
            '& *': { mb: 3 },
            [theme.breakpoints.down('md')]: {
              width: '100%'
            }
          })}
        >
          {SKELETON_CONFIG.map((width, index) => (
            <Skeleton
              key={index}
              width={`${width}%`}
            />
          ))}
        </Paper>
      ))}
    </Stack>
  </Box>
);

export default InsightsSkeleton;
