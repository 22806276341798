import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { Box, Typography } from '@mui/material';
import { ChartSeries } from '../../useChartSeries';
import FullScreenLegend from './FullScreenLegend';
import useFullScreenChart from './useFullScreenChart';
import Loader from 'components/Loader';
const FullScreenChart = ({
  chartSeries,
  error,
  loading
}: {
  chartSeries: ChartSeries[] | undefined;
  error: boolean;
  loading: boolean;
}) => {
  const { chartObject, options, chartComponentRef } = useFullScreenChart({ chartSeries });

  if (error)
    return (
      <Box
        sx={{
          width: '100%',
          height: '90vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Typography color='info'>
          An error occurred while retrieving your data. Our team is looking into it.
        </Typography>
        <Typography color='info'>Please try again shortly.</Typography>
      </Box>
    );

  if (loading)
    return (
      <Box
        sx={{
          width: '100%',
          height: '90vh'
        }}
      >
        <Loader />
      </Box>
    );

  return (
    <Box
      className='chart-container'
      sx={{ mr: 2, backgroundColor: 'background.paper', position: 'relative ' }}
    >
      {' '}
      <Box className='stock-chart-fullscreeen'>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'stockChart'}
          options={options}
          ref={chartComponentRef}
        />
      </Box>
      {chartObject?.series && <FullScreenLegend chart={chartObject} />}
    </Box>
  );
};
export default FullScreenChart;
